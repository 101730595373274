import React from 'react'

//mui
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

//components
import Label from '../custom-styled/label';
import CustomButton from '../custom-styled/button';
import CustomLottie from '../learning-aid/components/custom-lottie';
import CustomSelect from '../custom-styled/custom-select';

//lotties
import lotties from '../../lotties';

//utils
import commonUtil from '../../utils/commonUtil';

//styles
import { makeStyles } from "@mui/styles";

const ChallengeCard = ({
    id,
    element,
    challengeTypeImg,
    challengeNumber,
    challengeName,
    designType,
    handleEdit,
    _copyClick,
    handleDelete,
    handleSequence,
    sequenceChangedFor = [],
    selectedChallengeForCopy = null,
    isCopying = false,
    handleCopyChallenge,
    handleCancelCopy,
    microskills,
    selectedMsForCopy,
    setSelectedMsForCopy,
    showAnimation }) => {
    const classes = useStyles();
    const microskillsObject = commonUtil.objectify(microskills, 'id');
    const gameDesignType = {
        TF_BuildingBlocks: "Building Blocks",
        TF_CrazyCars: "Crazy Cars",
        TF_TastyTruth: "Tasty Truth",
        TF_SoundRight: "Sounds Right",
        MCQ_TikiMonkey: "Tiki Monkey",
        MCQ_MasterBlaster: "Master Blaster",
        MCQ_Football: "Football",
        MCQ_StarArcadia: "Star Arcadia",
        MTRR_PopcornMatch: "Popcorn Match",
        MTRR_TrashTrivia: "Trash Trivia",
        MTRR_CrackerMania: "Cracker Mania",
        MTRR_LearnoCasino: "Learn-O Casino",
        MTRR_BucketBlast: "Bucket Blast",
        MA_SpaceRocks: "Space Rocks",
        MA_BalloonPop: "Balloon Pop",
        MA_RevealThePicture: "Reveal The Picture",
        MA_WordSearch: "Word Search",
        LA_SLA: "SLA",
        LA_ILA: "Learning Aid",
        SL_MAN: "Spotlight",
        SCORM_V12: "Scorm",
        FORM:"Form"
    }

    const getDesignName = (designType) => {
        return gameDesignType[designType]
    }

    const calculateChallengeTime = (ele) => {
        if (ele.designType === 'LA_ILA') {
            let totalSeconds = ele.cardCount * 30;
            if (totalSeconds < 60) {
                return `${totalSeconds} Seconds`
            }
            if (totalSeconds === 60) {
                return `${parseInt(totalSeconds / 60)} Minute`
            }
            return `${parseInt(totalSeconds / 60)} Minutes`
        }
        return ``
    }
    const getDetails = () => {
        if (element.designType === 'LA_ILA') {
            return ` | ${element.cardCount} Cards | ${calculateChallengeTime(element)}`
        }
        if (element.type === 'GBA') {
            return ` | ${element.questionCount} Questions`

        }
        if (element.type === 'SL') {
            return ``

        }
        return ``

    }

    const challengeStatus = () => {
        const status = {
            PENDING: "fa-regular fa-circle",
            INPROGRESS: "fa-regular fa-circle-dot",
            COMPLETED: "fa-solid fa-circle-check"
        }

        return status[element.status];
    }

    let copiedToMessage = selectedMsForCopy.length > 1 ? `Copied to ${selectedMsForCopy.length} Microskills.` : `Copied to ${selectedMsForCopy.length} Microskill.`

    return (
        <div className={`${classes.card} ${sequenceChangedFor.includes(element.id) ? classes.highlighted : ""}`}>
            {
                showAnimation === element.id ?
                    <div className={classes.animationCard}>
                        <CustomLottie animationData={lotties.copyChallenge} />
                        <h5>Challenge Copied Successfully!</h5>
                        <h6>{copiedToMessage}</h6>
                    </div> : null
            }
            {
                (element.sequence !== 1 && selectedChallengeForCopy !== element.id) ?
                    <div className={`${classes.angle} ${classes.angleLeft}`} onClick={() => handleSequence(element, 'left', element.sequence - 1)}>
                        <i className="fa-solid fa-angle-left"></i>
                    </div> : null
            }
            {
                (element.sequence !== 4 && selectedChallengeForCopy !== element.id) ?
                    <div className={`${classes.angle} ${classes.angleRight}`} onClick={() => handleSequence(element, 'right', element.sequence - 1)}>
                        <i className="fa-solid fa-angle-right"></i>
                    </div> : null
            }


            <div className={classes.imageBox}>
                <img
                    src={challengeTypeImg} alt=''
                    style={{
                        height: element.type === 'SL' ? '5.2rem' : '',
                        width: element.type === 'SL' ? '5.2rem' : '6rem', 
                        aspectRatio: 1
                    }} />
            </div>
            <h4>{challengeName}</h4>
            {selectedChallengeForCopy === element.id ?
                <div className={classes.copyContainer}>
                    <Label labelText={'Copy to:'} fs={'4.6cqi'} fw={800} />
                    <div className={classes.selectWrapper}>
                    <CustomSelect
                        defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Microskill', selected: selectedMsForCopy, dataObject: microskillsObject })}
                        withSelectAllOption={true}
                        listData={microskills}
                        selected={selectedMsForCopy}
                        setSelected={setSelectedMsForCopy}
                        withCustomSelect={false}
                        width='100%'
                    />
                    </div>
                  
                    <div className={classes.copyCtaBox}>
                        {isCopying ?
                            <LoadingButton
                                loading
                                loadingPosition="center"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                            >
                            </LoadingButton>
                            : <CustomButton
                                variant='filled'
                                textColor='#fff'
                                borderColor='#f4511e'
                                bgcolor='#f4511e'
                                fs="0.7rem"
                                fw={900}
                                ls={1}
                                btnText={'Save'}
                                handleClick={handleCopyChallenge}
                            />}
                        <CustomButton
                            textColor='#f4511e'
                            borderColor='#f4511e'
                            bgcolor='#fff'
                            fs="0.7rem"
                            fw={900}
                            ls={1}
                            btnText={'Cancel'}
                            handleClick={handleCancelCopy}
                        />
                    </div>
                </div>
                :
                <>
                    <h5 className={classes.status}>
                        <i className={challengeStatus()}></i>
                        <span>{element.status}</span>
                    </h5>
                    <h6 style={{ fontWeight: "500", }}>{getDesignName(designType)}{getDetails()} </h6>
                    <div className={classes.btnBox}>
                        <img onClick={() => handleEdit(element)} src='/images/icons/pencil-edit-button.svg' title='Edit' alt='' />
                        <img onClick={() => _copyClick(element)} src="/images/icons/duplicate.svg" title='Copy' alt='' />
                        <img onClick={(e) => handleDelete(e, id)} src="/images/icons/delete-photo.svg" title='Delete' alt='' />
                    </div>
                </>
            }
        </div >
    )
}

export default ChallengeCard


const useStyles = makeStyles(() => ({

    card: {
        // border: '2px solid black',
        '-webkit-box-shadow': '0px 2px 6px 0px rgba(0,0,0,0.2)',
        '-moz-box-shadow': '0px 2px 6px 0px rgba(0,0,0,0.2)',
        boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.2)',
        borderRadius: '5px',
        // height: '25rem',
        width: '23%',
        aspectRatio: 0.72,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '1rem',
        position: 'relative',
        containerType: 'inline-size',
        transition: '1s',
        gap: '1.2rem',
        '& h4, h6': {
            textAlign: 'center',
            // marginBottom: '8cqi',
            color: "#0D034B",
            fontFamily: "Montserrat",
            fontSize: "5.5cqi",
            fontWeight: "800",
        },
        '& h6': {
            fontFamily: "Montserrat",
            color: "rgb(112, 112, 112, 0.8)",
            fontSize: "5cqi",
            fontWeight: "700",
        }


    },
    animationCard: {
        position: 'absolute',
        height: '100%',
        width: '90%',
        backgroundColor: '#fff',
        zIndex: 999,
        '& > div': {
            height: '50%'
        },
        '& > h5, h6': {
            color: "rgb(0,0,0)",
            fontFamily: "Montserrat",
            fontSize: "5.8cqi",
            marginTop: '13cqi',
            letterSpacing: "0.1cqi",
            fontWeight: 800,
            textAlign: 'center'
        },
    },
    highlighted: {
        '-webkit-box-shadow': '0px 2px 13px 2px rgba(110, 186, 0, 0.6) !important',
        '-moz-box-shadow': '0px 2px 13px 2px rgba(110, 186, 0, 0.6) !important',
        boxShadow: '0px 2px 13px 2px rgba(110, 186, 0, 0.6) !important',
    },
    angle: {
        border: '1px solid #f4511e',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '8cqi',
        height: '8cqi',
        borderRadius: '50%',
        backgroundColor: '#f4511e',
        cursor: 'pointer',
        bottom: '65cqi',
        '& > i': {
            color: '#fff',
            fontSize: "5cqi",
        },
        transition: '0.5s',
        '&:hover': {
            transform: 'scale(0.9)'
        },
    },
    angleLeft: {
        left: '3cqi',
    },
    angleRight: {
        right: '3cqi',
    },
    status: {
        // marginBottom: '8cqi',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& i': {
            marginRight: '0.5rem',
            fontSize: "1rem",
        },
        '& .fa-circle-dot': {
            color: '#f3b401'
        },
        '& .fa-circle-check': {
            color: 'green'
        },
        '& span': {
            color: "#0D034B",
            fontFamily: "Montserrat",
            fontSize: "4cqi",
            fontWeight: 800,
        },
    },
    imageBox: {
        display: 'flex',
        justifyContent: 'center',
        // marginBottom: '1.5rem',
        height: '33cqi',
        '& img': {
            // height: '6rem',
            // width: '6rem'
        }
    },
    btnBox: {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        gap: '1.7rem',
        '& img': {
            height: '1rem',
            width: '0.8rem',
            "&:hover": {
                filter: 'invert(42%) sepia(30%) saturate(7472%) hue-rotate(352deg) brightness(100%) contrast(92%)'
            }
        }
    },
    copyContainer: {
        // border: '1px solid',
        height: '40cqi',
    },
    selectWrapper: {
        position: 'relative',
        height: '12cqi',
        maxHeight: '12cqi',
        marginTop: '5cqi'
    },
    copyCtaBox: {
        // border: '1px solid',
        display: 'flex',
        marginTop: '21cqi',
        gap: '8cqi',
        height: '13cqi',
        '& .MuiButtonBase-root': {
            maxHeight: '100%',
            width: '50%',
            padding: 0,
            borderRadius: '1px',
            fontSize: '3cqi',
            borderWidth: '0.6cqi'

        },
        '& .MuiButton-filled:hover': {
            borderColor: '#f4511e',
            backgroundColor: '#f4511e',

        }
    }
}))
