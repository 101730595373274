import axios from 'axios';
import { keys, urls } from '../configs';
import { store } from '../redux/store';
import { logout } from "../redux/auth/auth.api";

const baseConfig = {
    baseURL: `${urls.baseUrl}${urls.apiV1}`,
    mode: 'no-cors',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-api-key': keys.apiKey
    }
}

export const fetch = axios.create(baseConfig);

const authInstance = axios.create(baseConfig);

authInstance.interceptors.request.use((config) => {
    const session = JSON.parse(localStorage.getItem('session'));
    config.headers['x-access-token'] = session.accessToken;
    config.headers['x-refresh-token'] = session.refreshToken;
    return config;
});

authInstance.interceptors.response.use(
    response => {
        return response
    },

    function (error) {

        console.log("error:", error.response);
        if (error.response.status === 401 || error.response.status === 403) {
            // return error.response;
            // console.log('Logout initiated');
            store.dispatch(logout);
        }
        return Promise.reject(error)
    }
)


export const authFetch = authInstance;

const multipartFormDataConfig = {
    baseURL: `${urls.baseUrl}${urls.apiV1}`,
    mode: 'no-cors',
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'x-api-key': keys.apiKey
    }
}

const authInstanceFormData = axios.create(multipartFormDataConfig);

authInstanceFormData.interceptors.request.use((config) => {
    const session = JSON.parse(localStorage.getItem('session'));
    config.headers['x-access-token'] = session.accessToken;
    config.headers['x-refresh-token'] = session.refreshToken;
    return config;
});

export const authFetchFormData = authInstanceFormData;

//azure-openai
const openaiBaseConfig = {
    baseURL: `${urls.openAiBaseUrl}`,
    mode: 'no-cors',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'api-key': keys.openaiApiKey
    }
}

export const authOpenaiInstance = axios.create(openaiBaseConfig);

const azureSearchServiceConfig = {
    ...openaiBaseConfig,
    baseURL: urls.azureSearchServiceBaseUrl,
    headers: {
        ...openaiBaseConfig.headers,
        'api-key': 'Wsbmbfo9T86SmHABe7moK9jgBBMmG6TqDzOLbMzhq7AzSeCGtN0Y'
    }
}

export const authAzureSearchServiceInstance = axios.create(azureSearchServiceConfig);