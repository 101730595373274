import React, { useState, useEffect, useRef, useContext } from 'react';
import Papa from 'papaparse';

//mui
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

//components
import QuestionAnswer from '../question-answer-component';
import LinearWithValueLabel from '../lenear-progress-bar';
import Label from '../label';
import Disclaimer from '../../../disclaimer';
import CustomButton from "../../../custom-styled/button";
import CustomInput from '../../../custom-styled/custom-input';
import ControlledSwitches from '../../../custom-styled/switch-button';
import RollingTextInput from '../roll-over-input';

//validations
import gbaValidations from '../../../../validations/gba-validations';

//constants
import constants from '../../../../constants';

//utils
import useQuery from '../../../../utils/useQuery';
import isEmpty from '../../../../utils/isEmpty';
import helperFunctions from './helper';

//styles
import useStyles from './index.styles';

//services
import azureServices from '../../../../services/microsoft-azure'
import challengeServices from '../../../../services/challenges';

import { GlobalStates } from '../../../../App';
import draglistData from '../../draglist-data';

const defaultInputFieldStates = { isDisabled: false, exceedText: '', isExceeded: false, isEmpty: false };

const Content = ({
    designType,
    list,
    setList,
    handleSaveContent,
    handleClearAndDelete,
    loadingCsv,
    setLoadingCsv,
    progress,
    setProgress,
    fileName,
    setFileName,
    setToggleCollapse,
    addContextBtn,
    setAddContextBtn,
    setAllExpanded,
    generating = false,
    setGenerating,
    selectedGame,
    skills,
    generateUsingAi,
    setGenerateUsingAi,
    prompt, setPrompt, maxQuestions, setMaxQuestions, deleteQuestionList, setDeleteQuestionList
}) => {
    const defaulRetries = 3;
    let roles = localStorage.getItem('roles') || [];
    const minRequiredQuestions = constants.GBA_VALIDATIONS[selectedGame.designType]?.minQuestionsRequired;
    const maxQuestionNumber = constants.GBA_VALIDATIONS[selectedGame.designType]?.appMaxQuestions;
    const menu = new Array(maxQuestionNumber).fill(0).map((ele, i) => (i + 1));
    const classes = useStyles();
    const query = useQuery();
    const isEdit = query.get('edit');
    const companyId = query.get('c_id');
    const microskillId = query.get('ms_id');
    const challengeId = query.get('challenge_id');
    const [globalStates] = useContext(GlobalStates);
    const [isRestricted, setIsRestricted] = useState(true)
    const [csvData, setCsvData] = useState([]);
    const [intervalCleared, setIntervalCleared] = useState(false);
    const [promptFieldStates, setPromptFieldStates] = useState(defaultInputFieldStates);
    let timer = useRef(null);
    let csvUploadInputRef = useRef(null);
    let gameType = constants.GBA_GAMETYPE[designType];
    let retryRef = useRef(defaulRetries);
    let quesCountRef = useRef(maxQuestions);

    useEffect(() => {
        setMaxQuestions(minRequiredQuestions)
    }, [])

    useEffect(() => {
        if (gbaValidations.validateContextList(designType, list)) {
            setIsRestricted(false);
        } else {
            setIsRestricted(true);
        }
    }, [list])

    useEffect(() => {
        if (intervalCleared) {
            updateListData();
            globalStates.handleToast(true, 'CSV uploaded successfully!', 'success');
            // setLoadingCsv(false);
            setProgress(100);
            setIntervalCleared(false);
        }
    }, [intervalCleared])

    const alphaToNumericIdx = {
        A: "0",
        B: "1",
        C: "2",
        D: "3",
        E: "4",
        F: "5",
        G: "6",
        H: "7",
        a: "0",
        b: "1",
        c: "2",
        d: "3",
        e: "4",
        f: "5",
        g: "6",
        h: "7",
    }

    const getIndex = (value) => {
        if (value?.length) value = value.trim();
        return alphaToNumericIdx[value]
    }

    const updateCollapseList = (data) => {
        let tempCollapseList = [];
        data.forEach((ele, i) => { tempCollapseList.push(i) });
        setToggleCollapse(tempCollapseList);
        setAllExpanded(false);
    }

    const isCsvFormatValid = (fields = []) => {
        const gameType = constants.GBA_GAMETYPE[designType];
        let isValid = false;
        const mcqCsvHeaders = ["Question (110 characters)", "Option A (60 Characters)", "Option B (60 Characters)", "Option C (60 Characters)", "Option D (60 Characters)", "Correct Answer (A/B/C/D)"];
        const tfCsvHeaders = ["Context (85 Characters)", "Question (100 Characters)", "Option A (True)", "Option B (False)", "Correct Answer (A/B)"];
        const mtrrCsvHeaders = ["Context (85 Characters)", "Question1 (66 Characters)", "Answer1 (45 Characters)", "Question2 (66 Characters)", "Answer2 (45 Characters)", "Question3 (66 Characters)", "Answer3 (45 Characters)", "Question4 (66 Characters)", "Answer4 (45 Characters)", "Question5 (66 Characters)", "Answer5 (45 Characters)"];
        const learnoCasinoHeaders = ["Context (85 Characters)", "Question1 (66 Characters)", "Answer1 (45 Characters)", "Question2 (66 Characters)", "Answer2 (45 Characters)", "Question3 (66 Characters)", "Answer3 (45 Characters)", "Question4 (66 Characters)", "Answer4 (45 Characters)"];
        const maCsvHeaders = ["Question (85 characters)", "Option A (63 characters)", "Option B (63 characters)", "Option C (63 characters)", "Option D (63 characters)", "Option E (63 characters)", "Option F (63 characters)", "Option G (63 characters)", "Option H (63 characters)", "Correct Answers (A,B,C,D) NO SPACES"];
        const soundsRightHeaders = ["Question (85 Characters)", "Option A (30 Characters)", "Option B (30 Characters)", "Correct Answer (A/B)"];
        let validHeaders = [];
        if (designType === 'TF_SoundRight') {
            validHeaders = soundsRightHeaders;
        } else if (designType === 'MTRR_LearnoCasino') {
            validHeaders = learnoCasinoHeaders;
        } else if (gameType === 'MCQ') {
            validHeaders = mcqCsvHeaders;
        } else if (gameType === 'TF') {
            validHeaders = tfCsvHeaders;
        } else if (gameType === 'MTRR') {
            validHeaders = mtrrCsvHeaders;
        } else if (gameType === 'MA') {
            validHeaders = maCsvHeaders;
        } else {
            return isValid;
        }
        if (fields.length !== validHeaders.length) return isValid;
        for (let i = 0; i < validHeaders.length; i++) {
            if (!fields.includes(validHeaders[i])) {
                isValid = false;
                break;
            } else {
                isValid = true;
            }
        }
        return isValid;
    }

    const updateListData = () => {
        let gamesWithMediaFiles = ["TF_SoundRight", "MA_RevealThePicture"]
        if (!gamesWithMediaFiles.includes(designType)) {
            const gameType = constants.GBA_GAMETYPE[designType];
            let stringified = designType === "MTRR_LearnoCasino" ? JSON.stringify(draglistData["MTRR_LearnoCasino"][0]) : JSON.stringify(draglistData[gameType][0]);
            let parsedJson = JSON.parse(stringified);
            let tempList = [];
            if (isEdit) tempList = [...list];

            if (gameType === "MCQ") {
                let filteredData = csvData.filter(ele => {
                    if (!isEmpty(ele)) {
                        if (isEmpty(ele["Question (110 characters)"]) && (!isEmpty(ele["Option A (60 Characters)"]) || !isEmpty(ele["Option B (60 Characters)"]) || !isEmpty(ele["Option C (60 Characters)"]) || !isEmpty(ele["Option D (60 Characters)"]))) {
                            return ele
                        } else if (!isEmpty(ele["Question (110 characters)"])) {
                            return ele
                        }

                    }

                });
                filteredData.forEach(ele => {
                    ele["0"] = ele["Option A (60 Characters)"] || "";
                    ele["1"] = ele["Option B (60 Characters)"] || "";
                    ele["2"] = ele["Option C (60 Characters)"] || "";
                    ele["3"] = ele["Option D (60 Characters)"] || "";
                    ele["Question"] = ele["Question (110 characters)"] || "";
                    let index = null;
                    let selected = getIndex(ele["Correct Answer (A/B/C/D)"]);
                    if (selected && parseInt(selected) < 4) {
                        index = selected;
                    }
                    ele["Correct Answer"] = index;
                })
                filteredData.forEach((element, i) => {
                    let tempObj = { ...parsedJson };
                    tempObj.id = i;
                    tempObj.sequence = i + 1;
                    tempObj.question = element.Question;
                    if (isEdit) {
                        tempObj.id = i + list.length;
                        tempObj.questionId = i + list.length;
                        tempObj.sequence = i + list.length + 1;
                    }
                    let tempAnswers = [];
                    tempObj.answers.forEach((item, idx) => {
                        let answerObj = { ...item };
                        answerObj.option = element[idx];
                        answerObj.value = false;
                        tempAnswers.push(answerObj);
                    })
                    if (element["Correct Answer"] !== null) tempAnswers[element["Correct Answer"]].value = true;
                    tempObj.answers = tempAnswers;
                    if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                        tempList.push(tempObj);
                    }
                });

            }
            if (gameType === "TF") {
                let filteredData = csvData.filter(ele => {
                    if (!isEmpty(ele)) {
                        if (isEmpty(ele["Question (100 Characters)"]) && (!isEmpty(ele["Option A (True)"]) || !isEmpty(ele["Option B (False)"]))) {
                            return ele
                        } else if (!isEmpty(ele["Question (100 Characters)"])) {
                            return ele
                        }

                    }
                });
                filteredData.forEach(ele => {
                    // ele["0"] = ele["A"];
                    // ele["1"] = ele["B"];
                    ele["0"] = "TRUE";
                    ele["1"] = "FALSE";
                    let index = null;
                    let selected = getIndex(ele["Correct Answer (A/B)"]);
                    if (selected && parseInt(selected) < 2) {
                        index = selected;
                    }
                    ele["Correct Answer"] = index;
                    ele["Question"] = ele["Question (100 Characters)"] || "";
                    ele["Context"] = ele["Context (85 Characters)"] || "";
                });
                filteredData.forEach((element, i) => {
                    let tempObj = { ...parsedJson };
                    tempObj.id = i;
                    tempObj.sequence = i + 1;
                    tempObj.question = element.Question || "";
                    tempObj.contextId = i;
                    let newContextOjb = { ...tempObj.contextObj };
                    newContextOjb.context = element["Context"] || "";
                    newContextOjb.id = i;
                    if (isEdit) {
                        tempObj.id = i + list.length;
                        tempObj.questionId = i + list.length;
                        tempObj.sequence = i + list.length + 1;
                        tempObj.contextId = i + list.length;
                        newContextOjb.id = i + list.length;
                    }
                    tempObj.contextObj = newContextOjb;
                    console.log('newContextOjb = ', newContextOjb);
                    let tempAnswers = [];
                    tempObj.answers.forEach((item, idx) => {
                        let answerObj = { ...item };
                        answerObj.option = element[idx] || "";
                        answerObj.value = false;
                        tempAnswers.push(answerObj);
                    })
                    if (element["Correct Answer"] !== null) tempAnswers[element["Correct Answer"]].value = true;
                    tempObj.answers = tempAnswers;
                    if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                        tempList.push(tempObj);
                    }
                });


            }
            if (gameType === "MA") {
                let filteredData = csvData.filter(ele => {
                    if (!isEmpty(ele)) {
                        if (isEmpty(ele["Question (85 characters)"]) && (!isEmpty(ele["Option A (63 characters)"]) || !isEmpty(ele["Option B (63 characters)"]) || !isEmpty(ele["Option C (63 characters)"]) || !isEmpty(ele["Option D (63 characters)"]) || !isEmpty(ele["Option E (63 characters)"]) || !isEmpty(ele["Option F (63 characters)"]) || !isEmpty(ele["Option G (63 characters)"]) || !isEmpty(ele["Option H (63 characters)"]))) {
                            return ele
                        } else if (!isEmpty(ele["Question (85 characters)"])) {
                            return ele
                        }

                    }
                });
                console.log('filteredData = ', filteredData);
                filteredData.forEach(ele => {
                    ele["Question"] = ele["Question (85 characters)"] || "";
                    ele["0"] = ele["Option A (63 characters)"] || "";
                    ele["1"] = ele["Option B (63 characters)"] || "";
                    ele["2"] = ele["Option C (63 characters)"] || "";
                    ele["3"] = ele["Option D (63 characters)"] || "";
                    ele["4"] = ele["Option E (63 characters)"] || "";
                    ele["5"] = ele["Option F (63 characters)"] || "";
                    ele["6"] = ele["Option G (63 characters)"] || "";
                    ele["7"] = ele["Option H (63 characters)"] || "";
                    let correctAnswersAlphaArray = ele["Correct Answers (A,B,C,D) NO SPACES"].split(",");
                    let correctAnswersNumeric = []
                    for (let i = 0; i < correctAnswersAlphaArray.length; i++) {
                        let index = getIndex(correctAnswersAlphaArray[i]);
                        // if (i > 3) break;
                        if (index) correctAnswersNumeric.push(index);
                    }
                    ele["CorrectAnswers"] = correctAnswersNumeric;
                    console.log('correctAnswersNumeric = ', correctAnswersNumeric);
                })
                filteredData.forEach((element, i) => {
                    let tempObj = { ...parsedJson };
                    tempObj.id = i;
                    tempObj.sequence = i + 1;
                    tempObj.question = element.Question;
                    if (isEdit) {
                        tempObj.id = i + list.length;
                        tempObj.questionId = i + list.length;
                        tempObj.sequence = i + list.length + 1;
                    }
                    let tempAnswers = [];
                    tempObj.answers.forEach((item, idx) => {
                        let answerObj = { ...item };
                        answerObj.option = element[idx];
                        answerObj.value = false;
                        tempAnswers.push(answerObj);
                    })
                    element.CorrectAnswers.forEach((ele, i) => {
                        if (i < 4) tempAnswers[ele].value = true;
                    })
                    console.log('tempAnswers = ', tempAnswers);
                    tempObj.answers = tempAnswers;
                    if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                        tempList.push(tempObj);
                    }
                });

            }
            if (gameType === "MTRR") {
                let filteredData = csvData.filter(ele => {
                    if (!isEmpty(ele)) {
                        if (isEmpty(ele["Context (85 Characters)"]) && (!isEmpty(ele["Question1 (66 Characters)"]) || !isEmpty(ele["Answer1 (45 Characters)"]) || !isEmpty(ele["Question2 (66 Characters)"]) || !isEmpty(ele["Answer2 (45 Characters)"]) || !isEmpty(ele["Question3 (66 Characters)"]) || !isEmpty(ele["Answer3 (45 Characters)"]) || !isEmpty(ele["Question4 (66 Characters)"]) || !isEmpty(ele["Answer4 (45 Characters)"]) || !isEmpty(ele["Question5 (66 Characters)"]) || !isEmpty(ele["Answer5 (45 Characters)"]))) {
                            return ele
                        } else if (!isEmpty(ele["Context (85 Characters)"])) {
                            return ele
                        }

                    }
                });
                console.log('filteredData = ', filteredData);
                filteredData.forEach(ele => {
                    ele["Context"] = ele["Context (85 Characters)"] || "";
                    ele["Question1"] = ele["Question1 (66 Characters)"] || "";
                    ele["Answer1"] = ele["Answer1 (45 Characters)"] || "";
                    ele["Question2"] = ele["Question2 (66 Characters)"] || "";
                    ele["Answer2"] = ele["Answer2 (45 Characters)"] || "";
                    ele["Question3"] = ele["Question3 (66 Characters)"] || "";
                    ele["Answer3"] = ele["Answer3 (45 Characters)"] || "";
                    ele["Question4"] = ele["Question4 (66 Characters)"] || "";
                    ele["Answer4"] = ele["Answer4 (45 Characters)"] || "";
                    ele["Question5"] = ele["Question5 (66 Characters)"] || "";
                    ele["Answer5"] = ele["Answer5 (45 Characters)"] || "";

                })
                for (let i = 0; i < filteredData.length; i++) {
                    let element = filteredData[i];
                    console.log(`element ${i} = `, element);
                    let tempObj = { ...parsedJson };
                    tempObj.id = i;
                    tempObj.contextId = i;
                    tempObj.sequence = i + 1;
                    let newContextOjb = { ...tempObj.contextObj };
                    newContextOjb.context = element["Context"] || "";
                    newContextOjb.id = i;
                    if (isEdit) {
                        tempObj.id = i + list.length;
                        tempObj.sequence = i + list.length + 1;
                        tempObj.contextId = i + list.length;
                        newContextOjb.id = i + list.length;
                    }
                    tempObj.contextObj = newContextOjb;


                    let newQuestionArr = [];
                    tempObj.questionArr.forEach((item, idx) => {
                        let questionObj = { ...item };
                        let questionKey = `Question${idx + 1}`;
                        let answerKey = `Answer${idx + 1}`;
                        questionObj.contextId = i;
                        questionObj.id = idx;
                        if (isEdit) questionObj.contextId = i + list.length;
                        questionObj.question = element[questionKey] || "";
                        let tempAnswer = [{ id: 0, option: "", value: true }];
                        tempAnswer[0].option = element[answerKey] || "";

                        questionObj.answers = tempAnswer;
                        newQuestionArr.push(questionObj);
                    })
                    tempObj.questionArr = newQuestionArr;
                    if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                        tempList.push(tempObj);
                    }
                }


            }
            console.log('tempList = ', tempList);
            if (!tempList.length) setAddContextBtn(true);
            if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                setAddContextBtn(true);
            }
            setList(tempList)
            updateCollapseList(tempList);
            return
        } else {
            //HANDLING GBA'S WITH MEDIA FILES HERE
            let stringified = JSON.stringify(draglistData[designType][0]);
            let parsedJson = JSON.parse(stringified);
            let tempList = [];
            if (isEdit) tempList = [...list];

            if (designType === "TF_SoundRight") {
                console.log('Its TF_SoundRight');
                let filteredData = csvData.filter(ele => {
                    if (!isEmpty(ele)) {
                        if (isEmpty(ele["Question (85 Characters)"]) && (!isEmpty(ele["Option A (30 Characters)"]) || !isEmpty(ele["Option B (30 Characters)"]))) {
                            return ele
                        } else if (!isEmpty(ele["Question (85 Characters)"])) {
                            return ele
                        }

                    }
                });
                console.log('filteredData = ', filteredData);
                filteredData.forEach(ele => {
                    ele["Question"] = ele["Question (85 Characters)"] || "";
                    ele["0"] = ele["Option A (30 Characters)"] || "";
                    ele["1"] = ele["Option B (30 Characters)"] || "";
                    let index = null;
                    let selected = getIndex(ele["Correct Answer (A/B)"]);
                    if (selected && parseInt(selected) < 2) {
                        index = selected;
                    }
                    ele["Correct Answer"] = index;
                });
                console.log('filteredData updated = ', filteredData);
                filteredData.forEach((element, i) => {
                    let tempObj = { ...parsedJson };
                    tempObj.id = i;
                    tempObj.sequence = i + 1;
                    tempObj.question = element.Question || "";
                    if (isEdit) {
                        tempObj.id = i + list.length;
                        tempObj.questionId = i + list.length;
                        tempObj.sequence = i + list.length + 1;
                        if (list.length) {
                            tempObj.fileName = list[0].fileName;
                            tempObj.audioFile = list[0].audioFile;
                            tempObj.contextId = list[0].contextId;
                            tempObj.contextObj = list[0].contextObj;
                        }
                    }
                    let tempAnswers = [];
                    tempObj.answers.forEach((item, idx) => {
                        let answerObj = { ...item };
                        answerObj.option = element[idx] || "";
                        answerObj.value = false;
                        tempAnswers.push(answerObj);
                    })
                    if (element["Correct Answer"] !== null) tempAnswers[element["Correct Answer"]].value = true;

                    tempObj.answers = tempAnswers;
                    if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                        tempList.push(tempObj);
                    }
                });
            }

            if (designType === "MA_RevealThePicture") {
                console.log('Its MA_RevealThePicture')
                let filteredData = csvData.filter(ele => {
                    if (!isEmpty(ele)) {
                        if (isEmpty(ele["Question (85 characters)"]) && (!isEmpty(ele["Option A (63 characters)"]) || !isEmpty(ele["Option B (63 characters)"]) || !isEmpty(ele["Option C (63 characters)"]) || !isEmpty(ele["Option D (63 characters)"]) || !isEmpty(ele["Option E (63 characters)"]) || !isEmpty(ele["Option F (63 characters)"]) || !isEmpty(ele["Option G (63 characters)"]) || !isEmpty(ele["Option H (63 characters)"]))) {
                            return ele
                        } else if (!isEmpty(ele["Question (85 characters)"])) {
                            return ele
                        }

                    }
                });
                console.log('filteredData = ', filteredData);
                filteredData.forEach(ele => {
                    ele["Question"] = ele["Question (85 characters)"] || "";
                    ele["0"] = ele["Option A (63 characters)"] || "";
                    ele["1"] = ele["Option B (63 characters)"] || "";
                    ele["2"] = ele["Option C (63 characters)"] || "";
                    ele["3"] = ele["Option D (63 characters)"] || "";
                    ele["4"] = ele["Option E (63 characters)"] || "";
                    ele["5"] = ele["Option F (63 characters)"] || "";
                    ele["6"] = ele["Option G (63 characters)"] || "";
                    ele["7"] = ele["Option H (63 characters)"] || "";
                    let correctAnswersAlphaArray = ele["Correct Answers (A,B,C,D) NO SPACES"].split(",");
                    let correctAnswersNumeric = []
                    for (let i = 0; i < correctAnswersAlphaArray.length; i++) {
                        // if (i > 3) break;
                        let index = getIndex(correctAnswersAlphaArray[i]);
                        if (index) correctAnswersNumeric.push(index);
                    }
                    ele["CorrectAnswers"] = correctAnswersNumeric;
                })
                filteredData.forEach((element, i) => {
                    let tempObj = { ...parsedJson };
                    tempObj.id = i;
                    tempObj.contextId = i;
                    tempObj.sequence = i + 1;
                    tempObj.question = element.Question;
                    if (isEdit) {
                        tempObj.id = i + list.length;
                        tempObj.questionId = i + list.length;
                        tempObj.contextId = i + list.length;
                        tempObj.sequence = i + list.length + 1;
                    }
                    let tempAnswers = [];
                    tempObj.answers.forEach((item, idx) => {
                        let answerObj = { ...item };
                        answerObj.option = element[idx];
                        answerObj.value = false;
                        tempAnswers.push(answerObj);
                    })
                    element.CorrectAnswers.forEach((ele, i) => {
                        if (i < 4) tempAnswers[ele].value = true;
                    })
                    console.log('tempAnswers = ', tempAnswers);
                    tempObj.answers = tempAnswers;
                    if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                        tempList.push(tempObj);
                    }
                });

            }

            if (!tempList.length) setAddContextBtn(true);
            if (tempList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                setAddContextBtn(true);
            }
            setList(tempList)
            updateCollapseList(tempList);

        }


    }

    const updateProgress = () => {
        timer = setInterval(() => {
            setProgress((prevProgress) => {
                let random = prevProgress + Math.floor(Math.random() * 20);
                if (prevProgress >= 100 || random >= 100) {
                    clearInterval(timer);
                    setIntervalCleared(true);
                    return 100
                } else {
                    return random
                }
            });
        }, 300);
    };

    const handleCsvUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return
        let maxLength = constants.GBA_VALIDATIONS[designType].maxQuestions;
        if (list.length >= maxLength) {
            let message = `The questions have reached their maximum limit`
            globalStates.handleToast(true, message, 'error');
            return
        }
        setProgress(0);
        setToggleCollapse([]);
        setAddContextBtn(false);
        setIsRestricted(true);
        setFileName(file.name);

        Papa.parse(file, {
            header: true, // Assumes the first row contains column headers
            dynamicTyping: false, // Automatically parse numbers and booleans
            complete: (result) => {
                // The parsed data is available in result.data
                let fields = result.meta.fields || [];
                if (isCsvFormatValid(fields)) {
                    setLoadingCsv(true);
                    setCsvData(result.data);
                    updateProgress();
                } else {
                    if (gbaValidations.validateContextList(designType, list)) {
                        setIsRestricted(false);
                    }
                    if (list.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                        setAddContextBtn(true);
                    }
                    setAllExpanded(true);
                    globalStates.handleToast(true, 'Incorrect CSV uploaded, please retry!', 'error');
                    return
                }

            },
            error: (error) => {
                // console.error('CSV Parsing Error:', error.message);
                if (gbaValidations.validateContextList(designType, list)) setIsRestricted(false);
                else setIsRestricted(true);
                globalStates.handleToast(true, 'Failed to upload csv', 'error');
            },
        });
        csvUploadInputRef.current.value = null
    };

    const getCsvFormat = () => {
        if (designType === "TF_SoundRight") gameType = "TF_SoundRight";
        if (designType === "MTRR_LearnoCasino") gameType = "MTRR_LearnoCasino";
        return constants.GBA_CSV_FORMATS[gameType]
    }

    const validate = () => {
        let isValid = true;
        if (!prompt.length) {
            setPromptFieldStates({ ...promptFieldStates, exceedText: '*Required', isEmpty: true });
            return isValid = false
        }
        return isValid
    }


    const updateListWithGenAiContent = async (genAiContent, isSoftDelete) => {
        setToggleCollapse([]);
        let type = selectedGame?.type;
        let newList = [];
        switch (type) {
            case 'MCQ':
                newList = await helperFunctions.getMcqList(selectedGame, genAiContent, skills, list);
                break;
            case 'TF':
                newList = await helperFunctions.getTfList(selectedGame, genAiContent, skills, list);
                break;
            case 'MA':
                newList = await helperFunctions.getMaList(selectedGame, genAiContent, skills, list);
                break;
            case 'MTRR':
                newList = await helperFunctions.getMtrrList(selectedGame, genAiContent, skills, list);
                break;
            default:
                break;
        }
        console.log('newList = ', newList);
        if (isSoftDelete) handleSoftDeleteQuestions();
        setList(newList);
        if (newList.length < maxQuestions) {
            globalStates.handleToast(true, 'Not enough content for the requested number of questions. Please upload additional content', 'info');
        } else {
            globalStates.handleToast(true, 'Content generated successfully!', 'success');
        }
        updateCollapseList(newList);

        if (gbaValidations.validateContextList(designType, newList)) {
            setIsRestricted(false);
        }
        if (newList.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
            setAddContextBtn(true);
        }
    }

    const parseData = async (str, isSoftDelete) => {
        try {
            const parsed = JSON.parse(str);
            console.log('parsed = ', parsed);
            await updateListWithGenAiContent(parsed, isSoftDelete);
            setGenerating(false);
        } catch (error) {
            console.log('error = ', error);
            console.log('retryRef.current = ', retryRef.current);
            if (retryRef.current > 0 && quesCountRef.current > 1) {
                console.log('Retrying... ');
                quesCountRef.current = quesCountRef.current - 1
                // console.log('Retrying... quesCountRef = ',quesCountRef.current);
                handleGenerate({ quesCount: quesCountRef.current, isRetry: true })
                retryRef.current = retryRef.current - 1
                return
            }
            globalStates.handleToast(true, 'The requested information is out of the scope of the documents provided', 'error');
            setGenerating(false);
            if (gbaValidations.validateContextList(designType, list)) {
                setIsRestricted(false);
            }
            if (list.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                setAddContextBtn(true);
            }

        }
    }


    const handleSoftDeleteQuestions = async () => {
        const tempDeletionIds = [];
        list.forEach((item) => {
            if (selectedGame.type === 'MTRR') {
                if (typeof item.questionArr[0].id === 'string') {
                    item.questionArr.forEach(ele => {
                        if (typeof ele.id === 'string') tempDeletionIds.push(ele.id)
                    })
                }
            } else {
                const id = item.questionId ? item.questionId : item.id;
                if (typeof id === 'string') tempDeletionIds.push(id)
            }

        })
        setDeleteQuestionList([...deleteQuestionList, ...tempDeletionIds]);

    }

    const handleSavePrompt = async (prompt) => {
        const payload = { companyId, microskillId, challengeId, prompt }
        await challengeServices.saveGenAiPrompt(payload)
    }

    const handleGenerate = async ({ inScope = true, quesCount = maxQuestions, isRetry = false }) => {
        // console.log('handleGenerate quesCount = ',quesCount);

        const isValid = validate();
        if (!isValid) return;
        setGenerating(true);
        setToggleCollapse([]);
        setAddContextBtn(false);
        setIsRestricted(true);
        setAllExpanded(false);
        let isSoftDelete = false;
        // if (isEdit && list.length) {
        //     const confirm = window.confirm('All existing contexts/questions will be replaced. Are you sure you want to continue?');
        //     if (confirm) {
        //         isSoftDelete = true
        //     } else {
        //         setGenerating(false);
        //         if (gbaValidations.validateContextList(designType, list)) {
        //             setIsRestricted(false);
        //         }
        //         if (list.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
        //             setAddContextBtn(true);
        //         }
        //         return
        //     }
        // }
        console.log('GENERATING...');
        const messages = [{
            "role": "user",
            "content": helperFunctions.getStitchedPrompt(prompt, selectedGame, quesCount)
        }]
        if (!isRetry) handleSavePrompt(prompt)
        const response = await azureServices.createContentUsingCustomData({ messages, indexName: companyId, inScope });
        console.log('response = ', response);
        if (response.status === 200 || response.status === 201) {
            let content = response?.data?.choices[0]?.message?.content;
            if (content) {
                let cleanedString = content.replace(/\n/g, '').replace(/```json/g, '').replace(/```/g, '').replace(/ {4,}/g, '');
                console.log('cleanedString = ', cleanedString);
                await parseData(cleanedString, isSoftDelete);
            }
        } else {
            globalStates.handleToast(true, 'Something went wrong, please try again', 'error');
            setGenerating(false);
            if (gbaValidations.validateContextList(designType, list)) {
                setIsRestricted(false);
            }
            if (list.length < constants.GBA_VALIDATIONS[designType].maxQuestions) {
                setAddContextBtn(true);
            }
        }
    }

    const handlePromptChange = (e) => {
        setPromptFieldStates(defaultInputFieldStates);
        setPrompt(e.target.value);
        retryRef.current = defaulRetries;
        quesCountRef.current = maxQuestions;
    }

    const handleMaxQuestionChange = (e) => {
        setMaxQuestions(e.target.value);
        retryRef.current = defaulRetries;
        quesCountRef.current = maxQuestions;
    }


    return (
        <div className={classes.mainContainer}>
            <div className={classes.row1}>
                {roles.includes('GEN_AI') && selectedGame.type === 'MCQ' && <div>
                    <Label labelText={"Add content using AI:"} fs='0.8rem' p='0.5rem 0' />
                    <ControlledSwitches checked={generateUsingAi} handleChange={() => setGenerateUsingAi(!generateUsingAi)} />
                    <span className={classes.betaText}>Beta</span>
                </div>}
                {
                    generateUsingAi ?
                        <>
                            <Label labelText={"Enter prompt"} fs='0.8rem' p='0.5rem 0' />
                            {/* <CustomInput
                                withLimitWarning={true}
                                value={prompt}
                                placeholder='Enter your prompt...'
                                isDisabled={promptFieldStates.isDisabled}
                                m='0.5rem 0'
                                handleChange={handlePromptChange}
                                exceedText={promptFieldStates.exceedText}
                                isExceeded={promptFieldStates.isExceeded}
                                isEmpty={promptFieldStates.isEmpty}
                            /> */}
                            <RollingTextInput
                                value={prompt}
                                setValue={setPrompt}
                                handleChange={handlePromptChange}
                            />
                            <Label labelText={selectedGame.type === 'MCQ' ? "Maximum Questions" : "Maximum Contexts"} fs='0.8rem' p='0.5rem 0' />
                            <FormControl
                                sx={{ m: 1, minWidth: 120 }}
                                className={classes.selectMenuWrapper}
                            >
                                <Select
                                    value={maxQuestions}
                                    onChange={handleMaxQuestionChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {
                                        menu.map((ele, i) => (
                                            <MenuItem key={i} value={ele}>{ele}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            {generating ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    className={classes.btn}
                                    sx={{ backgroundColor: 'inherit !important' }}
                                >
                                    GENERATING...
                                </LoadingButton> :
                                <Button title='GENERATE' disabled={false} sx={{ ':disabled': { bgcolor: '#ccc', borderColor: '#ccc' } }} className={`${classes.btn} ${classes.saveBtn}`} onClick={handleGenerate}>
                                    GENERATE
                                </Button>
                            }

                        </>
                        : <>
                            <Label labelText={"CSV Upload:"} fs='0.8rem' p='0.5rem 0' />
                            {loadingCsv ? <div className={`${classes.uploadProgressBox}`}>
                                <i className="fa-solid fa-file-csv"></i>
                                <div>
                                    <h6>{fileName}</h6>
                                    <LinearWithValueLabel progress={progress} />
                                </div>
                            </div> : null}
                            <div className={`${classes.csvUploadBoxContainer} ${classes.flexCenter}`}>
                                <div className={classes.csvUploadBox}>
                                    <label className={classes.uploadIcon}>
                                        <i className="fa-solid fa-cloud-arrow-up"></i>
                                        <input ref={csvUploadInputRef} style={{ display: 'none' }} type='file' accept='.csv' onChange={handleCsvUpload} />
                                    </label>
                                    <h4>Upload CSV file here</h4>
                                    <h5>or</h5>
                                    <a
                                        href={getCsvFormat()}
                                        target="_blank"
                                        download
                                    >
                                        Download CSV format</a>
                                </div>
                            </div>
                        </>
                }


                {/* <CustomButton
                    fs="0.8rem"
                    fw={700}
                    borderColor='#fff'
                    btnText="CREATE CONTENT USING AI"
                    handleClick={() => setGenerateContentModal(true)}
                    width={"auto"}
                /> */}
            </div>

            {!generating ? <div className={classes.row2}>
                <Disclaimer p='0.5rem 0' lh={2.5} fs='0.59rem' />
                <div className={classes.warningNote}>Mandatory : {constants.GBA_VALIDATIONS[designType]['minQuestionsRequired']} </div>
                <div className={classes.btnBox}>
                    {
                        isRestricted ?
                            //instead of loading btn here we have to show disabled button until all validation are clear on context list
                            <Button variant="contained" disabled>
                                NEXT
                            </Button> :
                            <Button title='Save' onClick={(e) => handleSaveContent(list)} className={`${classes.btn} ${classes.saveBtn}`}>
                                NEXT
                            </Button>

                    }


                    <Button
                        title='Clear all'
                        onClick={handleClearAndDelete}
                        className={`${classes.btn} ${classes.clearBtn}`}>
                        CLEAR & DELETE
                    </Button>
                </div>
            </div> : null}
        </div>
    )
}

export default Content;