import React, { useEffect, useRef, useState, useContext } from 'react'

//constants
import constants from '../../constants';

//components
import GameType from './components/game-type';
import Content from './components/content';
import Settings from './components/settings';
import About from './components/about';
import ContextList from './components/context-list';
import LoadingContainer from '../loading-container';
import ConfirmDialog from '../confirm-dialog';
import GenerateContentModal from './components/generate-content';

//services
import challengesServices from '../../services/challenges';
import skillsServices from '../../services/skills';
import contextServices from '../../services/context'
import microskillServices from '../../services/microskill';
import azureServices from '../../services/microsoft-azure';

//utils
import useRouter from '../../utils/useRouter';
import commonUtil from '../../utils/commonUtil';
import useQuery from '../../utils/useQuery';

//styles
import useStyles from './index.styles';

//global-states
import { GlobalStates } from '../../App';

//validations
import gbaValidations from '../../validations/gba-validations';

//constant-data
import draglistData from './draglist-data';
import CustomButton from '../custom-styled/button';
import isEmpty from '../../utils/isEmpty';
import helperFunctions from './components/content/helper';

// material UI : 
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardMedia, CircularProgress, Grid } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 900,
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    padding: '0 1rem 1rem 1rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    padding: '4',
    zIndex: '99999',
    outline: 'none',
}

const aboutTitleTM = "Tiki Monkeys"
const aboutDescription = " This game design comprises of multiple elements working in conjunction to create a distracting environment for the learners. This in turn mimics the real-life situation for the learner to test their ability to think clearly on their toes, while in a noisy, crowded public spaces."

const GameBasedAssessment = () => {
    const classes = useStyles();
    const router = useRouter();
    let query = useQuery();
    let isEdit = query.get('edit');
    const companyId = query.get("c_id");
    const projectId = query.get("p_id");
    const microskillId = query.get('ms_id');
    const challengeId = query.get('challenge_id');
    const menuData = ['Game Type', '', 'Content', '', 'Settings']
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState({ firstColumn: 'Game Type', secondColumn: 'About' })
    const [menuItemStatus, setMenuItemStatus] = useState({ gametype: 'inProgress', content: 'pending', settings: 'pending' })
    const [selectedGame, setSelectedGame] = useState({
        index: 0,
        type: 'MCQ',
        name: 'Tiki Monkeys',
        designType: 'MCQ_TikiMonkey',
        aboutTitle: aboutTitleTM,
        aboutDescription,
        json: constants.GBA_GAMETYPE_DATA[0].challenges[0].json,
        screen: constants.GBA_SCREENS["MCQ_TikiMonkey"]
    })
    const [selectedGBATypeData, setSelectedGBATypeData] = useState(constants.GBA_GAMETYPE_DATA[0]);
    const [list, setList] = useState([]);
    const [skills, setSkills] = useState([]);
    const [isDragable, setIsDragable] = useState(true);//TO HANDLE IS LIST DRAGABLE OR NOT
    const [isDisableAll, setIsDisableAll] = useState(false);
    const [isContextAdded, setIsContextAdded] = useState(false);
    const [allExpanded, setAllExpanded] = useState(false);
    const [addContextBtn, setAddContextBtn] = useState(true);
    const [toggleCollapse, setToggleCollapse] = useState([0]);
    const [isSavingChallenge, setIsSavingChallenge] = useState(false);
    const [isEditName, setEditName] = useState(false);
    const [challengeName, setChallengeName] = useState('')
    const [globalStates, setGlobalStates] = useContext(GlobalStates);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmLoader, setConfirmLoader] = useState(false);
    const [confirmationData, setConfirmationData] = useState(null);
    const [dialogContent, setDialogContent] = useState("");

    const isDelete = useRef(false);
    const confirmRightBtnText = useRef('Delete All');
    // setConfirmationData({ name: 'microskill', data: data });
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [deleteQuestionList, setDeleteQuestionList] = useState([]);
    const [generateContentModal, setGenerateContentModal] = useState(false);
    const [topic, setTopic] = useState('');
    const [questions, setQuestions] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [reGenerating, setReGenerating] = useState(false);
    const [generateUsingAi, setGenerateUsingAi] = useState(false);
    const [maxQuestions, setMaxQuestions] = useState(0);
    const [prompt, setPrompt] = useState('');
    const [settings, setSettings] = useState({
        scorePerQuestion: 0,
        gameplaySpeed: "NORMAL",
        totalTime: 0,
        maxAttempts: 0,
        questionSequence: "RANDOM",
        activateAnswerAttempt: null,
    });
    const [isEditSetting, setIsEditSetting] = useState(false);
    const [leastUsedGbaModal, setLeastUsedGbaModal] = useState(false);
    const [unusedGbaTypes, setUnusedGbaTypes] = useState([]);
    const [isUnusedGbasLoading, setIsUnusedGbasLoading] = useState(false);

    const sequence = query.get("seq");

    useEffect(() => {
        setGlobalStates({ ...globalStates, showMsName: true });
        let setting = localStorage.getItem('gba-setting');
        if (setting) setSettings(JSON.parse(setting));
        return () => {
            setGlobalStates({ ...globalStates, showMsName: false });
            localStorage.removeItem('gba-setting');
        }
    }, []);

    useEffect(() => {
        setChallengeName(query.get("challengeName"));
        fetchSkills(microskillId);
        if (!challengeId) {
            //here its a new challenge
            let defaultSelectedGame = JSON.stringify(draglistData['MCQ']);
            setList(JSON.parse(defaultSelectedGame));
        } else {
            fetchChallengeData();
        }

    }, []);

    useEffect(() => {
        const fetchUnusedGbaTypes = async () => {
            try {
                setIsUnusedGbasLoading(true);
                const response = await microskillServices.fetchLeastUsedGbasList({ companyId });
                if (response.status === 200 || response.status === 201) {
                    const allDesigns = response.data.data;
                    const unusedGbaTypeData = [];
                    allDesigns.forEach(function (design) {
                        const { designType } = design;
                        const gameType = designType.split('_')[0];

                        const gameTypeData = constants.GBA_GAMETYPE_DATA.filter(element => element.type === gameType)[0]
                        const challengeTypeData = gameTypeData.challenges.filter(element => element.designType === designType)[0]
                        unusedGbaTypeData.push(challengeTypeData);
                    });
                    setUnusedGbaTypes(unusedGbaTypeData);
                    if (!isEdit && !challengeId && unusedGbaTypeData.length) {
                        setLeastUsedGbaModal(true);
                    }
                    setIsUnusedGbasLoading(false);
                } else {
                    setIsUnusedGbasLoading(false);
                }
            } catch (error) {
                setIsUnusedGbasLoading(false);
            }
        };

        fetchUnusedGbaTypes();
    }, []);



    const fetchChallengeData = async (withLoading = true) => {
        if (withLoading) setIsLoading(true);
        let res = await challengesServices.getChallengeData(microskillId, challengeId);
        if (res.status === 200) {
            let challengeData = res.data.data;
            setChallengeName(challengeData.name);
            const [typeResult] = constants.GBA_GAMETYPE_DATA.filter((element) => element.type === challengeData.gameType);
            const designResult = typeResult.challenges.filter((element) => element.designType === challengeData.designType);
            setSelectedGame({
                index: 0,
                type: challengeData.gameType,
                name: challengeData.designType,
                designType: challengeData.designType,
                aboutTitle: aboutTitleTM,
                aboutDescription,
                json: designResult[0].json,
                screen: designResult[0].screen
            });
            if (challengeData.Questions.length) {
                let tempList = []
                switch (challengeData.gameType) {
                    case 'MCQ':
                        challengeData.Questions.forEach((element, i) => {
                            let stringifiedJson = JSON.stringify(draglistData[challengeData.gameType][0]);
                            let parsedJson = JSON.parse(stringifiedJson);

                            parsedJson.id = i;
                            parsedJson.questionId = element.id;
                            parsedJson.question = element.question;
                            parsedJson.validations = [];
                            parsedJson.sequence = element.sequence;
                            parsedJson.status = "inProgress";
                            if (element.Skill !== null) {
                                parsedJson.status = "done";
                                parsedJson.skillId = element.Skill.id;
                            }
                            console.log('parsed json = ', parsedJson);
                            let tempAnswers = [];
                            element.answers.forEach(ele => {
                                ele.option = ele.answer;
                                ele.value = ele.isCorrect;
                                tempAnswers.push(ele);
                            })
                            if (tempAnswers.length < 4) {
                                for (let i = tempAnswers.length; i < 4; i++) {
                                    tempAnswers.push({
                                        id: 0,
                                        option: "",
                                        value: false
                                    })
                                }
                            }
                            parsedJson.answers = tempAnswers;
                            tempList.push(parsedJson);
                        })
                        console.log('tempList = ', tempList);
                        if (tempList.length >= constants.GBA_VALIDATIONS[challengeData.designType].maxQuestions) {
                            //HIDING ADD CONTEXT BUTTON - THEN THIS FUNCTION {handleAddContext} WILL NO LONGER BE CALLED.
                            setAddContextBtn(false);
                        }
                        tempList.sort((a, b) => a.sequence - b.sequence);
                        setList(tempList);
                        setToggleCollapse([tempList[0].id]);
                        break;
                    case 'MTRR':
                        // let tempList = []
                        let contextsMap = new Map();
                        challengeData.Questions.forEach(item => {
                            if (!isEmpty(item?.Context?.id) && !contextsMap.has(item.Context.id)) {
                                contextsMap.set(item.Context.id, item.Context)
                            }
                        })
                        console.log('contextsMap = ', contextsMap);

                        let questionsMap = new Map();
                        challengeData.Questions.forEach(item => {
                            if (!isEmpty(item?.contextId)) {
                                if (questionsMap.has(item.contextId)) {
                                    //do nothing
                                    let result = questionsMap.get(item.contextId);
                                    result = [...result, item];
                                    questionsMap.set(item.contextId, result);
                                } else {
                                    questionsMap.set(item.contextId, [item]);
                                }
                            }

                        })
                        console.log('questionsMap = ', questionsMap);

                        let i = 0;
                        for (let [key, element] of questionsMap) {
                            let stringifiedJson = JSON.stringify(draglistData[challengeData.gameType][0]);
                            if (challengeData.designType === "MTRR_LearnoCasino") {
                                stringifiedJson = JSON.stringify(draglistData["MTRR_LearnoCasino"][0]);
                            }
                            let parsedJson = JSON.parse(stringifiedJson);
                            parsedJson.id = i;
                            parsedJson.contextObj = contextsMap.get(key);
                            parsedJson.validations = [];
                            parsedJson.status = "inProgress";
                            let tempQuestionArr = [];
                            element.forEach(item => {
                                item.answers[0].option = item.answers[0].answer;
                                item.answers[0].value = item.answers[0].isCorrect;
                                item.isQuestionEmpty = false;
                                item.emptyAnswers = [{ isEmpty: false }];
                                if (item.Skill !== null) {
                                    parsedJson.skillId = item.Skill.id;
                                    parsedJson.status = "done";
                                }
                                tempQuestionArr.push(item);
                            })
                            console.log(`tempQuestionArr ${i} = `, tempQuestionArr);
                            tempList.push({ ...parsedJson, questionArr: tempQuestionArr });
                            i++;

                        }
                        let newList = []
                        let maxQuestions = 5
                        if (challengeData.designType === "MTRR_LearnoCasino") maxQuestions = 4;
                        tempList.forEach(element => {
                            if (element.questionArr.length < maxQuestions) {
                                let tempQuestionArr = [...element.questionArr];
                                for (let i = element.questionArr.length; i < 5; i++) {
                                    tempQuestionArr.push({
                                        id: i,
                                        question: '',
                                        isQuestionEmpty: false,
                                        emptyAnswers: [{ isEmpty: false }],
                                        answers: [{
                                            id: 0,
                                            option: "",
                                            value: true
                                        }]
                                    })
                                }
                                element.questionArr = tempQuestionArr;
                            }
                            newList.push(element);
                        })
                        if (newList.length >= constants.GBA_VALIDATIONS[challengeData.designType].maxQuestions) {
                            //HIDING ADD CONTEXT BUTTON - THEN THIS FUNCTION {handleAddContext} WILL NO LONGER BE CALLED.
                            setAddContextBtn(false)
                        }
                        setList(newList);
                        setToggleCollapse([newList[0].id]);
                        break;
                    case 'TF':
                        // code block
                        let contextsMapTF = new Map();
                        challengeData.Questions.forEach(item => {
                            if (item.Context !== null) {
                                contextsMapTF.set(item.Context.id, item.Context);
                            }

                        })
                        console.log('contextsMapTF = ', contextsMapTF);

                        challengeData.Questions.forEach((element, i) => {
                            let stringifiedJson = JSON.stringify(draglistData[challengeData.gameType][0]);
                            let parsedJson = JSON.parse(stringifiedJson);
                            if (challengeData.designType === "TF_SoundRight") {
                                stringifiedJson = JSON.stringify(draglistData["TF_SoundRight"][0]);
                                parsedJson = JSON.parse(stringifiedJson);
                                if (element.contextId !== null) {
                                    let url = contextsMapTF.get(element.contextId)["context"];
                                    let urlToFileName = commonUtil.remoteUrlToFilename(url);
                                    console.log('urlToFileName = ', urlToFileName);
                                    parsedJson.fileName = urlToFileName;
                                    parsedJson.audioFile = url;
                                }

                            }
                            parsedJson.id = i;
                            parsedJson.contextId = element.contextId;
                            if (element.Context !== null) {
                                parsedJson.contextObj = element.Context;
                            }
                            parsedJson.questionId = element.id;
                            parsedJson.question = element.question;
                            parsedJson.validations = [];
                            parsedJson.sequence = element.sequence;
                            parsedJson.status = "inProgress";
                            if (element.Skill !== null) {
                                parsedJson.status = "done";
                                parsedJson.skillId = element.Skill.id;
                            }
                            console.log('parsed json = ', parsedJson);
                            let tempAnswers = [];
                            element.answers.forEach(ele => {
                                ele.option = ele.answer;
                                ele.value = ele.isCorrect;
                                tempAnswers.push(ele);
                            })
                            if (tempAnswers.length < 2) {
                                for (let i = tempAnswers.length; i < 2; i++) {
                                    tempAnswers.push({
                                        id: 0,
                                        option: "",
                                        value: false
                                    })
                                }
                            }
                            parsedJson.answers = tempAnswers;
                            tempList.push(parsedJson);
                        })
                        console.log('tempList = ', tempList);
                        if (tempList.length >= constants.GBA_VALIDATIONS[challengeData.designType].maxQuestions) {
                            //HIDING ADD CONTEXT BUTTON - THEN THIS FUNCTION {handleAddContext} WILL NO LONGER BE CALLED.
                            setAddContextBtn(false)
                        }
                        tempList.sort((a, b) => a.sequence - b.sequence);
                        setList(tempList);
                        setToggleCollapse([tempList[0].id]);
                        break;
                    case 'MA':
                        challengeData.Questions.forEach((element, i) => {
                            let stringifiedJson = JSON.stringify(draglistData[challengeData.gameType][0]);
                            let parsedJson = JSON.parse(stringifiedJson);
                            if (challengeData.designType === "MA_RevealThePicture") {
                                stringifiedJson = JSON.stringify(draglistData["MA_RevealThePicture"][0]);
                                parsedJson = JSON.parse(stringifiedJson);
                                let url = element?.Context?.context;
                                let urlToFileName = 'No file chosen'
                                if (!isEmpty(url)) {
                                    urlToFileName = commonUtil.remoteUrlToFilename(url);
                                    parsedJson.imageFile = url;
                                    parsedJson.contextId = element.contextId;
                                }
                                parsedJson.fileName = urlToFileName;
                            }
                            parsedJson.id = i;
                            parsedJson.questionId = element.id;
                            parsedJson.question = element.question;
                            parsedJson.validations = [];
                            parsedJson.sequence = element.sequence;
                            parsedJson.status = "inProgress";
                            if (element.Skill !== null) {
                                parsedJson.status = "done";
                                parsedJson.skillId = element.Skill.id;
                            }
                            console.log('parsed json = ', parsedJson);
                            let tempAnswers = [];
                            element.answers.forEach(ele => {
                                ele.option = ele.answer;
                                ele.value = ele.isCorrect;
                                tempAnswers.push(ele);
                            })
                            if (tempAnswers.length < 8) {
                                for (let i = tempAnswers.length; i < 8; i++) {
                                    tempAnswers.push({
                                        id: 0,
                                        option: "",
                                        value: false
                                    })
                                }
                            }
                            parsedJson.answers = tempAnswers;
                            tempList.push(parsedJson);
                        })
                        console.log('tempList MA = ', tempList);
                        if (tempList.length >= constants.GBA_VALIDATIONS[challengeData.designType].maxQuestions) {
                            setAddContextBtn(false)
                        }
                        tempList.sort((a, b) => a.sequence - b.sequence);
                        setList(tempList);
                        setToggleCollapse([tempList[0].id]);
                        break;
                    default:
                    // code block
                }
            }
            setMenuItemStatus({ ...menuItemStatus, gametype: 'done', content: 'inProgress' });
            setSelectedMenuItem({ firstColumn: 'Content', secondColumn: 'ContextList' });

            if (!isEdit) {
                //Setting default list data for selected design type 
                let selectedGameListData = JSON.stringify(draglistData[challengeData.gameType]);
                if (challengeData.designType === 'TF_SoundRight') {
                    selectedGameListData = JSON.stringify(draglistData['TF_SoundRight']);
                }
                if (challengeData.designType === 'MA_RevealThePicture') {
                    selectedGameListData = JSON.stringify(draglistData['MA_RevealThePicture']);
                }
                if (challengeData.designType === 'MTRR_LearnoCasino') {
                    selectedGameListData = JSON.stringify(draglistData['MTRR_LearnoCasino']);
                }
                setList(JSON.parse(selectedGameListData));
            }
            setIsLoading(false);
        }
    }

    const fetchSkills = async (microskillId) => {
        // const res = await skillsServices.fetchSkills(companyId); 
        const res = await microskillServices.fetchMicroskillSkills(microskillId);
        if (res.status === 200 || res.status === 201) {
            let data = res.data.data;
            if (!data.length) {
                globalStates.handleToast(true, "Please add skill tags to this Microskill before continuing", "error");
            }
            let tempSkills = [];
            data.forEach(ele => {
                let tempObj = {
                    id: ele.Skill.id,
                    skill: ele.Skill.skill
                }
                tempSkills.push(tempObj)
            })
            setSkills(tempSkills);
        }
    }

    const handleMenuClick = (i, ele) => {
        // console.log("handleMenuClick = ", ele);
        if (!isEdit) {
            return
        } else if (ele === 'Game Type') {
            //NOT ALLOWING TO CHANGE GAME TYPE FOR NOW
            const result = constants.GBA_GAMETYPE_DATA.filter((element) => element.type === selectedGame.type);
            setSelectedGBATypeData(result[0]);
            setMenuItemStatus({ ...menuItemStatus, gametype: 'inProgress', content: 'pending', settings: 'pending' });
            setSelectedMenuItem({ ...selectedMenuItem, firstColumn: ele })
            setIsDisableAll(true) // disable edit on list
            return
        } else if (ele === 'Content') {
            //Here clicked item is Content
            // return
            // if (menuItemStatus.gametype !== 'done') {
            //     return
            // }
            setMenuItemStatus({ ...menuItemStatus, gametype: 'done', content: 'inProgress', settings: 'pending' });
            isDisableAll && setIsDisableAll(false)
            setSelectedMenuItem({ firstColumn: ele, secondColumn: 'ContextList' });
            return
        } else {
            //Here clicked item is Setting 
            // return
            handleSaveContent(list);
            setMenuItemStatus({ ...menuItemStatus, gametype: 'done', content: 'done', settings: 'inProgress' });
            // if (menuItemStatus.gametype === 'done' && menuItemStatus.content === 'done') {
            //     isDisableAll && setIsDisableAll(false)
            //     setSelectedMenuItem({ firstColumn: ele, secondColumn: 'ContextList' })
            //     return
            // }
            return
        }

    }

    const handleExpandCollapseAll = () => {
        if (!allExpanded) {
            let validatedList = []
            list.forEach(element => {
                let validatedListitem = gbaValidations.validateListitem(selectedGame.type, selectedGame.designType, element)
                validatedList.push(validatedListitem)
            })
            setList(validatedList);
            setAllExpanded(!allExpanded);
            setToggleCollapse([]);
            return
        }
        let temp = []
        for (let i = 0; i < list.length; i++) {
            temp.push(i);
            // temp.push(list[i].id || i);
        }
        setToggleCollapse(temp)
        setAllExpanded(!allExpanded)

    }

    const handleSaveContent = (list) => {
        //HERE FIRST WE HAVE TO CHECK ALL VALIDATIONS OF GBA CONTEXT, QUESTION, RESPONSE AND IF THERE IS ANY ERROR WE HAVE TO COLLAPSE ALL LISTITEMS AND SHOW ERRORS
        let validatedList = []
        list.forEach(element => {
            let validatedListitem = gbaValidations.validateListitem(selectedGame.type, selectedGame.designType, element)
            validatedList.push(validatedListitem)
        })
        setList(validatedList);
        if (gbaValidations.isEachListitemValid(validatedList)) {
            //HERE EACH LIST ITEM IS VALID
            setIsDisableAll(true) //DISABLING EDIT ON LIST
            setAddContextBtn(false) //DISABLING ADD CONTEXT/QUESTION
            setIsDragable(false) //DISABLING DRAG ON LIST 
            // setToggleCollapse([]) //COLLAPSING ALL ITEMS IN LIST
            setMenuItemStatus({ ...menuItemStatus, content: 'done', settings: 'inProgress' })
            setSelectedMenuItem({ firstColumn: 'Settings', secondColumn: 'ContextList' })
        } else {
            //ELSE HERE I HAVE TO COLLAPSE ALL LIST ITEMS AND SHOW ERRORS
            setAllExpanded(true);
            setToggleCollapse([]) //COLLAPSING ALL ITEMS IN LIST TO SHOW ERRORS!
        }

    }

    const handleGoback = () => {
        if (isSavingChallenge) return
        setIsDragable(true) //ENABLING DRAG ON LIST
        if (list.length < constants.GBA_VALIDATIONS[selectedGame.designType].maxQuestions) {
            setAddContextBtn(true)
        } else {
            setAddContextBtn(false)
        }
        setIsDisableAll(false) //ENABLING EDIT ON LIST.
        setSelectedMenuItem({ firstColumn: 'Content', secondColumn: 'ContextList' })
    }

    const handleAddContext = () => {
        let tempList = [...list];
        console.log('tempList = ', tempList);
        let stringified = JSON.stringify(draglistData[selectedGame.type][0]);
        if (selectedGame.designType === 'TF_SoundRight') {
            stringified = JSON.stringify(draglistData["TF_SoundRight"][0]);
        }
        if (selectedGame.designType === 'MA_RevealThePicture') {
            stringified = JSON.stringify(draglistData["MA_RevealThePicture"][0]);
        }
        if (selectedGame.designType === 'MTRR_LearnoCasino') {
            stringified = JSON.stringify(draglistData["MTRR_LearnoCasino"][0]);
        }
        let parsed = JSON.parse(stringified);

        console.log('handleAddContext parsed = ', parsed);
        if (selectedGame.type === 'MCQ') {
            tempList.push({
                ...parsed,
                id: tempList.length,
                questionId: tempList.length,
                sequence: tempList.length + 1
            })
        } else if (selectedGame.type === 'TF') {
            if (selectedGame.designType === 'TF_SoundRight') {
                //pushing same audio file and filename for new question
                if (tempList.length > 0) {
                    tempList.push({
                        ...parsed,
                        id: tempList.length,
                        sequence: tempList.length + 1,
                        questionId: tempList.length,
                        audioFile: tempList[0].audioFile,
                        fileName: tempList[0].fileName,
                        isAudioEmpty: tempList[0].isAudioEmpty,
                        contextId: tempList[0].contextId,
                        contextObj: tempList[0].contextObj,
                    })
                } else {
                    tempList.push({
                        ...parsed,
                        id: tempList.length,
                        sequence: tempList.length + 1,
                        questionId: tempList.length
                    })
                }

            } else {
                tempList.push({
                    ...parsed,
                    id: tempList.length,
                    sequence: tempList.length + 1,
                    contextId: tempList.length,
                    contextObj: { id: tempList.length, type: 'TEXT', context: '' },
                    questionId: tempList.length
                });
            }

        } else if (selectedGame.type === 'MA') {
            let newObj = {
                ...parsed,
                id: tempList.length,
                sequence: tempList.length + 1,
                questionId: tempList.length
            }
            if (isEdit && selectedGame.designType === 'MA_RevealThePicture') newObj.contextId = tempList.length;
            tempList.push(newObj);
        } else if (selectedGame.type === 'MTRR') {
            tempList.push({
                ...parsed,
                id: tempList.length,
                sequence: tempList.length + 1,
                contextObj: { ...parsed.contextObj, id: tempList.length }
            });
        }
        setList(tempList);
        setIsContextAdded(true);

        //TO KEEP NEWLY ADDED CONTEXT OPEN IN LIST BY DEFAULT
        if (tempList.length > 1) {
            const tempToggleCollapse = [...toggleCollapse]
            tempToggleCollapse.push(list.length)
            setToggleCollapse(tempToggleCollapse)
        }

        // TO LIMIT ADD CONTEXT 
        if (tempList.length >= constants.GBA_VALIDATIONS[selectedGame.designType].maxQuestions) {
            //HIDING ADD CONTEXT BUTTON - THEN THIS FUNCTION {handleAddContext} WILL NO LONGER BE CALLED.
            setAddContextBtn(false)
            return
        }
    }

    const stepperMenuStatus = (ele) => {
        let temp = ele.split(" ").join("").toLowerCase();
        const status = {
            pending: "fa-regular fa-circle",
            inProgress: "fa-regular fa-circle-dot",
            done: "fa-solid fa-circle-check"
        }

        return status[menuItemStatus[temp]];
    }

    const getActiveClass = (ele) => {
        let temp = ele.split(" ").join("").toLowerCase();
        if (menuItemStatus[temp] !== 'pending') {
            return classes.liActive;
        }
        return ""
    }

    const handleClearAndDelete = () => {
        if (!list.length) {
            globalStates.handleToast(true, "Nothing to clear", "info");
            return
        }
        isDelete.current = true;
        setDialogContent("Are you sure you want to delete all the questions?");
        confirmRightBtnText.current = "Delete All";
        setConfirmationData({ name: "delete-all", data: null });
        setConfirmDialogOpen(true);
        return
    }

    const handleConfirm = (confirm) => {
        if (confirm) {
            switch (confirmationData.name) {
                case "delete-all":
                    setConfirmLoader(true);
                    let notAllowed = ['MTRR']
                    let listToBeDeleted = [];
                    if (!notAllowed.includes(selectedGame.type)) {
                        //removing locally added questions
                        listToBeDeleted = list.filter(ele => {
                            if (typeof (ele.questionId) === 'string') return ele
                        })
                    } else {
                        //handling deletion for MTRR questions
                        listToBeDeleted = list.map(ele => {
                            return ele.questionArr
                        })
                        listToBeDeleted = listToBeDeleted.flat()
                        listToBeDeleted = listToBeDeleted.filter(ele => {
                            if (typeof (ele.id) === 'string') return ele
                        })
                    }
                    if (!listToBeDeleted.length) {
                        setList([]);
                        setAddContextBtn(true);
                        setConfirmLoader(false);
                        setConfirmDialogOpen(false);
                        globalStates.handleToast(true, 'Deleted successfully!', 'success');
                        window.location.reload();
                        return
                    }
                    listToBeDeleted.forEach(async (ele, i) => {
                        let id = notAllowed.includes(selectedGame.type) ? ele.id : ele.questionId;
                        const res = await challengesServices.deleteQuestion({ id });
                        if (res.status === 200 || res.status === 201) {
                            if (i === listToBeDeleted.length - 1) {
                                const challengeId = query.get("challenge_id");
                                let updateStatusPayload = {
                                    challengeId: challengeId,
                                    status: 'INPROGRESS'
                                }
                                await challengesServices.updateChallenge(updateStatusPayload);
                                setList([]);
                                setConfirmLoader(false);
                                setConfirmDialogOpen(false);
                                setAddContextBtn(true);
                                isDelete.current = false;
                                globalStates.handleToast(true, 'Deleted successfully!', 'success');
                                window.location.reload();
                            }
                        }

                    })

                    break;
                case "unmount":
                    router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);
                    break;
                default:
                    return
            }

        } else {
            setConfirmDialogOpen(false);
        }
    }

    //EDIT CHALLENGE QUESTIONS
    const handleEditChallenge = async () => {
        let payload = {
            companyId: companyId,
            microskillId: microskillId,
            challengeId: challengeId,
            status: 'COMPLETED',
            gameType: selectedGame.type,
            maxScore: 120,
            setting: settings,
            skills: [],
            questions: []
        };
        let newList = [];
        switch (selectedGame.type) {
            case 'MCQ':
                list.forEach(element => {
                    let tempObj = {
                        id: element.questionId,
                        question: element.question,
                        answers: element.answers,
                        skillId: element.skillId,
                        sequence: element.sequence
                    }
                    newList.push(tempObj);
                })
                let skillsMcq = commonUtil.getSkillsArray(newList);
                newList = commonUtil.getSequenceUpdated(newList);
                payload = { ...payload, questions: newList, skills: skillsMcq }
                break;
            case 'TF':
                payload = { ...payload, contexts: [] }
                if (selectedGame.designType === 'TF_SoundRight') {
                    if (typeof (list[0].audioFile) === 'object') {
                        // here audio file has been changed
                        let json = {
                            id: list[0].contextId,
                            companyId: companyId,
                            GBAMediaFile: list[0].audioFile,
                            type: 'AUDIO'
                        }
                        let updateContextPayload = await commonUtil.jsonToFormData(json);
                        const updateContextResponse = await contextServices.updateContext(updateContextPayload);
                        if (updateContextResponse.status === 200 || updateContextResponse.status === 201) {
                            let data = updateContextResponse.data.data;
                            let tempContext = {
                                id: data.id,
                                context: data.context,
                                type: "AUDIO"
                            }
                            let tempList = [];
                            list.forEach(element => {
                                let tempObj = {
                                    id: element.questionId,
                                    contextId: tempContext.id,
                                    question: element.question,
                                    answers: element.answers,
                                    skillId: element.skillId,
                                    sequence: element.sequence
                                }
                                tempList.push(tempObj);
                            })
                            tempList = commonUtil.getSequenceUpdated(tempList);
                            payload = {
                                ...payload,
                                contexts: [tempContext],
                                questions: tempList,
                                setting: { ...settings, textToSpeechS3Url: settings?.textToSpeechContext?.length ? data.context : null }
                            }
                        } else {
                            console.log('failed to upload audio while edit');
                            return
                        }
                    } else {
                        //here audio file has not been changed
                        let tempContext = {
                            id: list[0].contextObj.id,
                            context: list[0].contextObj.context,
                            type: "AUDIO"
                        }
                        let tempList = [];
                        list.forEach(element => {
                            let tempObj = {
                                id: element.questionId,
                                contextId: tempContext.id,
                                question: element.question,
                                answers: element.answers,
                                skillId: element.skillId,
                                sequence: element.sequence
                            }
                            tempList.push(tempObj);
                        })
                        tempList = commonUtil.getSequenceUpdated(tempList);
                        payload = {
                            ...payload,
                            contexts: [tempContext],
                            questions: tempList,
                            setting: { ...settings, textToSpeechS3Url: settings?.textToSpeechContext?.length ? settings?.textToSpeechS3Url : null }
                        }
                    }
                    // return
                } else {
                    //here games are other than sounds-right
                    let contextList = commonUtil.getContextList(list);
                    let tempQuestions = [];

                    list.forEach(element => {
                        let tempObj = {
                            id: element.questionId,
                            contextId: element.contextId,
                            question: element.question,
                            answers: element.answers,
                            skillId: element.skillId,
                            sequence: element.sequence
                        }
                        tempQuestions.push(tempObj);
                    })
                    tempQuestions = commonUtil.getSequenceUpdated(tempQuestions);
                    payload = {
                        ...payload,
                        contexts: contextList,
                        questions: tempQuestions
                    }
                }
                let skillsTF = commonUtil.getSkillsArray(payload.questions);
                payload = { ...payload, skills: skillsTF }
                break;
            case 'MA':
                if (selectedGame.designType === 'MA_RevealThePicture') {
                    //first checking if any image file is changed or not
                    let tempContexts = [];
                    let tempMaList = [...list];

                    for (let i = 0; i < tempMaList.length; i++) {
                        let element = tempMaList[i];
                        if (typeof (element.imageFile) === 'object') {
                            // here image file has been changed
                            let json = {
                                id: element.contextId,
                                companyId: companyId,
                                GBAMediaFile: element.imageFile,
                                type: 'PICTURE'
                            }
                            if (typeof (element.contextId) === "number") {
                                json = {
                                    companyId: companyId,
                                    microskillId: microskillId,
                                    challengeId: challengeId,
                                    GBAMediaFile: element.imageFile,
                                    type: 'PICTURE'
                                }
                                let contextPayload = await commonUtil.jsonToFormData(json);
                                const createContextResponse = await contextServices.createContext(contextPayload);
                                if (createContextResponse.status === 200 || createContextResponse.status === 201) {
                                    let data = createContextResponse.data.data;
                                    let contextObj = {
                                        id: data.id,
                                        context: data.context,
                                        type: "PICTURE"
                                    }
                                    tempContexts.push(contextObj);
                                    tempMaList[i].contextId = data.id;

                                } else {
                                    console.log('failed to upload image while edit');
                                    return
                                }
                            } else {
                                let updateContextPayload = await commonUtil.jsonToFormData(json);
                                const updateContextResponse = await contextServices.updateContext(updateContextPayload);
                                if (updateContextResponse.status === 200 || updateContextResponse.status === 201) {
                                    let data = updateContextResponse.data.data;
                                    let contextObj = {
                                        id: data.id,
                                        context: data.context,
                                        type: "PICTURE"
                                    }
                                    tempContexts.push(contextObj);
                                } else {
                                    console.log('failed to upload image while edit');
                                    return
                                }
                            }

                        } else {
                            let contextObj = {
                                id: element.contextId,
                                context: element.imageFile,
                                type: "PICTURE"
                            }
                            tempContexts.push(contextObj);
                        }
                    }
                    let tempQuestions = [];
                    tempMaList.forEach(element => {
                        let newElement = {
                            id: element.questionId,
                            contextId: element.contextId,
                            question: element.question,
                            answers: element.answers,
                            skillId: element.skillId,
                            sequence: element.sequence
                        };
                        tempQuestions.push(newElement);
                    })
                    tempQuestions = commonUtil.getSequenceUpdated(tempQuestions);
                    let skillsMa = commonUtil.getSkillsArray(tempQuestions);
                    payload = {
                        ...payload,
                        contexts: tempContexts,
                        skills: skillsMa,
                        questions: tempQuestions
                    }
                }
                else {
                    //other than reveal the pictures
                    list.forEach(element => {
                        let newElement = {
                            id: element.questionId,
                            question: element.question,
                            answers: element.answers,
                            skillId: element.skillId,
                            sequence: element.sequence
                        };
                        newList.push(newElement);
                    })
                    newList = commonUtil.getSequenceUpdated(newList);
                    let skillsMa = commonUtil.getSkillsArray(newList);
                    payload = { ...payload, skills: skillsMa, questions: newList }
                }
                break;
            case 'MTRR':
                payload = { ...payload, contexts: [] };
                let finalList = commonUtil.getNewListMTRREdit(list);
                let skills = commonUtil.getSkillsArray(finalList);
                let tempContexts = commonUtil.getContextList(list);
                const newContexts = await commonUtil.getTextToSpeechUrl(tempContexts, selectedGame.designType, companyId, microskillId, challengeId); payload = {
                    ...payload,
                    skills: skills,
                    contexts: newContexts,
                    questions: finalList
                }
                break;
            default:
                break;
        }
        console.log('payload = ', payload);
        // return
        const response = await challengesServices.updateQuestions(payload);
        await _deleteQuestions()
        globalStates.handleProgressDialog(false);
        if (response.status === 200 || response.status === 201) {
            localStorage.setItem('isChallengeUpdated', true)
            router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);
        } else {
            globalStates.handleToast(true, 'Internal Server Error', 'error');
        }
    }

    const handleSaveChallenge = async () => {
        globalStates.handleProgressDialog(true, "Please wait...");
        if (isEdit) {
            handleEditChallenge();
            return
        }
        let payload = selectedGame.json;
        payload = {
            ...payload,
            companyId: companyId,
            microskillId: microskillId,
            challengeId: challengeId,
            maxScore: 120,
            status: 'COMPLETED',
            setting: settings
        }
        //PAYLOAD FOR MCQ
        if (selectedGame.type === "MCQ") {
            const skills = commonUtil.getSkillsArray(list);
            let sequenceUpdatedList = commonUtil.getSequenceUpdated(list);
            payload = {
                ...payload,
                questions: sequenceUpdatedList,
                skills: skills
            }
        }
        //PAYLOAD FOR TF
        if (selectedGame.type === "TF") {
            // setContexts(getNewList.contexts)
            if (selectedGame.designType === 'TF_SoundRight') {
                let json = {
                    companyId: companyId,
                    microskillId: microskillId,
                    challengeId: challengeId,
                    GBAMediaFile: list[0].audioFile,
                    type: 'AUDIO'
                }
                let contextPayload = await commonUtil.jsonToFormData(json);
                const createContextResponse = await contextServices.createContext(contextPayload);
                if (createContextResponse.status === 200 || createContextResponse.status === 201) {
                    let data = createContextResponse.data.data;
                    let tempContext = {
                        id: data.id,
                        context: data.context,
                        type: "AUDIO"
                    }
                    let tempList = [];
                    list.forEach(element => {
                        element.contextId = tempContext.id;
                        tempList.push(element)
                    })
                    tempList = commonUtil.getSequenceUpdated(tempList);
                    payload = {
                        ...payload,
                        contexts: [tempContext],
                        questions: tempList,
                        setting: { ...settings, textToSpeechS3Url: settings?.textToSpeechContext?.length ? data.context : null }
                    }
                } else {
                    console.log('failed to upload audio');
                    return
                }


            } else {
                let contextList = commonUtil.getContextList(list);
                let sequenceUpdatedList = commonUtil.getSequenceUpdated(list);
                payload = {
                    ...payload,
                    contexts: contextList,
                    questions: sequenceUpdatedList
                }
            }
            const skills = commonUtil.getSkillsArray(payload.questions);
            payload = {
                ...payload,
                skills: skills
            }
        }
        //PAYLOAD FOR MA
        if (selectedGame.type === "MA") {
            if (selectedGame.designType === 'MA_RevealThePicture') {
                let tempContexts = [];
                let tempList = [...list];
                for (let i = 0; i < tempList.length; i++) {
                    let json = {
                        companyId: companyId,
                        microskillId: microskillId,
                        challengeId: challengeId,
                        GBAMediaFile: tempList[i].imageFile,
                        type: 'PICTURE'
                    }
                    let contextPayload = await commonUtil.jsonToFormData(json);
                    const createContextResponse = await contextServices.createContext(contextPayload);
                    if (createContextResponse.status === 200 || createContextResponse.status === 201) {
                        let data = createContextResponse.data.data;
                        let contextObj = {
                            id: data.id,
                            context: data.context,
                            type: "PICTURE"
                        }
                        tempContexts.push(contextObj);
                        tempList[i].contextId = data.id;

                    } else {
                        console.log('Image upload failed');
                        return
                    }
                }
                let skillsMa = commonUtil.getSkillsArray(tempList);
                tempList = commonUtil.getSequenceUpdated(tempList);
                payload = {
                    ...payload,
                    contexts: tempContexts,
                    skills: skillsMa,
                    questions: tempList
                }
            } else {
                let skillsMa = commonUtil.getSkillsArray(list);
                let sequenceUpdatedList = commonUtil.getSequenceUpdated(list);
                payload = {
                    ...payload,
                    skills: skillsMa,
                    questions: sequenceUpdatedList
                }
            }


        }
        //PAYLOAD FOR MTRR
        if (selectedGame.type === "MTRR") {
            let finalList = commonUtil.getNewListMTRR(list);
            let skills = commonUtil.getSkillsArray(finalList);
            let tempContexts = commonUtil.getContextList(list);
            const newContexts = await commonUtil.getTextToSpeechUrl(tempContexts, selectedGame.designType, companyId, microskillId, challengeId);
            payload = {
                ...payload,
                skills: skills,
                contexts: newContexts,
                questions: finalList
            }

        }
        console.log('payload = ', payload);
        const res = await challengesServices.addQuestionsGba(payload);
        globalStates.handleProgressDialog(false);
        if (res.status === 200 || res.status === 201) {
            localStorage.setItem('isChallengeCreated', true)
            router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);
            return
        }
        if (res.status === 400 || res.status === 401) {
            if (res.data?.errors[0]?.message) {
                globalStates.handleToast(true, res.data?.errors[0]?.message, 'error');
            }
        }

        if (res.status === 500) {
            globalStates.handleToast(true, 'Internal server error', 'error');
        }

        return
    }

    const handleUpdateName = async () => {
        if (isEmpty(challengeName)) {
            globalStates.handleToast(true, 'Please enter the challenge name', 'error')
            return
        }
        let oldName = query.get("challengeName");
        let newName = challengeName;
        if (oldName === newName) {
            setEditName(false);
        } else {
            if (challengeId) {
                let payload = {
                    challengeId: challengeId,
                    name: challengeName
                }
                const res = await challengesServices.updateChallenge(payload);
                if (res.status === 200 || res.status === 201) {
                    globalStates.handleToast(true, 'Changes saved', 'success');
                    let path = `/microskill-studio/game-based-assessment?challengeName=${challengeName}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${challengeId}`
                    if (isEdit) path += `&&edit=${query.get('edit')}`
                    router.history.replace(path);
                    setEditName(false);
                } else {
                    globalStates.handleToast(true, 'Internal server error', 'error')
                }
            } else {
                setEditName(false);
            }
        }
    }

    const handleCancelNameChange = () => {
        let oldName = query.get("challengeName");
        setChallengeName(oldName);
        setEditName(false);
    }

    const handleBack = () => {
        if (list.length) {
            isDelete.current = false;
            setDialogContent("All unsaved changes will be lost, are you sure?");
            confirmRightBtnText.current = "Confirm";
            setConfirmationData({ name: "unmount", data: null });
            setConfirmDialogOpen(true);
        } else {
            router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);
        }

    }

    const handleSoftDeleteQuestion = async (item) => {
        console.log('list = ', list);
        const confirm = window.confirm('Do you really want to delete this?');
        if (confirm) {
            if (selectedGame.type === 'MTRR') {
                handleSoftDeleteMtrrContext(item);
                return
            }
            const id = item.questionId ? item.questionId : item.id;
            const tempList = [...list];
            let filtered;
            if (typeof id === 'string') {
                setDeleteQuestionList([...deleteQuestionList, id]);
                filtered = tempList.filter(x => x.questionId != id);
            } else {
                filtered = tempList.filter(x => x.id != id);
            }
            setList(filtered);
            if (filtered.length < constants.GBA_VALIDATIONS[selectedGame.designType].maxQuestions) {
                setAddContextBtn(true);
            }
            globalStates.handleToast(true, 'Deleted successfully!', 'success');
        }

    }

    const handleSoftDeleteMtrrContext = (item) => {
        const tempList = [...list];
        if (typeof item.questionArr[0].id === 'string') {
            let ids = [];
            item.questionArr.forEach(ele => {
                ids.push(ele.id)
            })
            setDeleteQuestionList([...deleteQuestionList, ...ids]);
        }
        const filtered = tempList.filter(x => x.id != item.id);
        setList(filtered);
        if (filtered.length < constants.GBA_VALIDATIONS[selectedGame.designType].maxQuestions) {
            setAddContextBtn(true);
        }
        globalStates.handleToast(true, 'Deleted successfully!', 'success');
    }

    const _deleteQuestions = async () => {
        if (!deleteQuestionList.length) return
        for (let i = 0; i < deleteQuestionList.length; i++) {
            let id = deleteQuestionList[i];
            await challengesServices.deleteQuestion({ id });
        }

    }

    const handleDismiss = () => {
        setLeastUsedGbaModal(false);
    }

    const createChallengeGBA = async (selectedGBATypeData, selectedGame) => {
        if (isEmpty(challengeName)) {
            globalStates.handleToast(true, 'Please enter the challenge name', 'error')
            return
        }

        if (!challengeId) {
            //HERE WE HAVE TO CREATE NEW CHALLENGE FIRST
            let payload = {
                companyId: companyId,
                microskillId: microskillId,
                name: challengeName,
                type: "GBA",
                gameType: selectedGBATypeData.type,
                designType: selectedGame.designType,
                sequence: parseInt(sequence),
                maxScore: 0,
                setting: {
                    scorePerQuestion: 0,
                    gameplaySpeed: "NORMAL",
                    totalTime: 0,
                    maxAttempts: 0,
                    questionSequence: "SEQUENCE"
                }
            }
            globalStates.handleProgressDialog(true, 'Please wait...');
            let res = await challengesServices.createChallenge(payload)
            globalStates.handleProgressDialog(false);
            if (res.status === 200 || res.status === 201) {
                let newChallengeId = res.data.data.id;
                setMenuItemStatus({ ...menuItemStatus, gametype: 'done', content: 'inProgress' })
                setSelectedMenuItem({ firstColumn: 'Content', secondColumn: 'ContextList' })
                router.history.replace(`/microskill-studio/game-based-assessment?microskillName=${query.get('microskillName')}&&challengeName=${challengeName}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${newChallengeId}`)//
                return
            } else {
                globalStates.handleToast(true, 'Internal server error', 'error');
            }
        } else {
            //HERE CHALLENGE ALREADY EXISTS
            setMenuItemStatus({ ...menuItemStatus, gametype: 'done', content: 'inProgress' })
            setSelectedMenuItem({ firstColumn: 'Content', secondColumn: 'ContextList' })
            return
        }


    }

    const handleUnusedGbaCardClick = (unusedCard) => {

        const { designType, name, aboutTitle, json, screen } = unusedCard;

        const gameType = constants.GBA_GAMETYPE[designType];

        let gameTypeData = constants.GBA_GAMETYPE_DATA.filter(e => e.type === gameType)[0];
        let index = gameTypeData.challenges.indexOf(gameType => gameType.designType === designType);

        setSelectedGBATypeData(gameTypeData);
        // setSelectedGame(unusedCard);
        let selectedGameTempObj = {
            ...unusedCard,
            index: index,
            designType: designType,
            name: name,
            aboutTitle: aboutTitle,
            json: json,
            screen: screen,
            type: gameType
        }
        setSelectedGame(selectedGameTempObj);

        let selectedJson = JSON.stringify(draglistData[gameType]);

        if (designType === "TF_SoundRight") {
            selectedJson = JSON.stringify(draglistData["TF_SoundRight"]);
        } else if (designType === "MA_RevealThePicture") {
            selectedJson = JSON.stringify(draglistData["MA_RevealThePicture"]);
        } else if (designType === "MTRR_LearnoCasino") {
            selectedJson = JSON.stringify(draglistData["MTRR_LearnoCasino"]);
        }

        setList(JSON.parse(selectedJson));
        setLeastUsedGbaModal(false)
        createChallengeGBA(gameTypeData, selectedGameTempObj);
    }

    const handleRegenerate = async (listItem) => {
        console.log('handleRegenerate listItem = ', listItem);
        setReGenerating(true);

        const messages = [{
            "role": "user",
            "content": helperFunctions.getRegeneratePrompt(listItem, selectedGame)
        }]
        const response = await azureServices.createContentUsingCustomData({ messages, indexName: companyId });
        console.log('response = ', response);
        if (response.status === 200 || response.status === 201) {
            try {
                let content = response?.data?.choices[0]?.message?.content;
                if (content) {
                    let cleanedString = content.replace(/\n/g, '').replace(/```json/g, '').replace(/```/g, '').replace(/ {4,}/g, '');
                    const parsed = JSON.parse(cleanedString);
                    if (parsed.length) {
                        let tempList = [...list]
                        for (let i = 0; i < tempList.length; i++) {
                            if (tempList[i].uuid === listItem.uuid) {
                                let tempAnswers = [...parsed[0].answers];
                                for (let j = tempAnswers.length; j < 4; j++) {
                                    tempAnswers.push({
                                        id: j,
                                        option: "",
                                        value: false,
                                        sequence: j + 1
                                    })
                                }
                                tempList[i] = {
                                    ...tempList[i],
                                    question: parsed[0]?.question ?? "",
                                    answers: tempAnswers
                                };

                                break;
                            }
                        }
                        setList(tempList)
                    }
                }


            } catch (error) {
                globalStates.handleToast(true, 'Failed to regenerate', 'error');
                setReGenerating(false);

            }

        } else {
            globalStates.handleToast(true, 'Failed to regenerate', 'error');
            setReGenerating(false);

        }

    }


    if (isLoading) {
        return (
            <LoadingContainer />
        )
    } else {
        return (
            <>
                <div className={classes.mainContainer}>
                    <div style={{ zIndex: confirmDialogOpen || generateContentModal ? 100 : 9999 }} className={classes.header}>
                        <img onClick={handleBack} src='/images/gba-assets/left-arrow.svg' alt='left-arrow' />
                        {isEditName ?
                            <div>
                                <input type='text' value={challengeName} onChange={(e) => setChallengeName(e.target.value)} />
                                <CustomButton
                                    btnText={'Save'}
                                    handleClick={handleUpdateName} />
                                <CustomButton
                                    btnText={'Cancel'}
                                    handleClick={handleCancelNameChange} />
                            </div>
                            : <h1>
                                {challengeName}
                                <img src='/images/icons/pencil-edit-button.svg' alt='' onClick={() => setEditName(true)} />
                            </h1>}
                    </div>
                    <div className={classes.row}>
                        <div className={classes.column1}>
                            <nav>
                                <ul className={classes.list}>
                                    {
                                        menuData.map((ele, i) => (
                                            (i % 2 === 0) ? // 0=GameType, 2=Content, 4=Settings
                                                <li key={i} className={getActiveClass(ele)} onClick={() => handleMenuClick(i, ele)}>
                                                    <i className={stepperMenuStatus(ele)}></i>
                                                    {ele}
                                                </li> :
                                                <span key={i}></span>
                                        ))
                                    }
                                </ul>
                            </nav>
                            {selectedMenuItem.firstColumn === 'Game Type' ?
                                <GameType
                                    selectedGame={selectedGame}
                                    setSelectedGame={setSelectedGame}
                                    selectedGBATypeData={selectedGBATypeData}
                                    setSelectedGBATypeData={setSelectedGBATypeData}
                                    list={list}
                                    setList={setList}
                                    isEdit={isEdit}
                                    challengeId={challengeId}
                                    setMenuItemStatus={setMenuItemStatus}
                                    setSelectedMenuItem={setSelectedMenuItem}
                                    menuItemStatus={menuItemStatus}
                                    globalStates={globalStates}
                                    setIsDisableAll={setIsDisableAll}
                                /> : null}
                            {selectedMenuItem.firstColumn === 'Content' ?
                                <Content
                                    designType={selectedGame.designType}
                                    list={list}
                                    setList={setList}
                                    handleSaveContent={handleSaveContent}
                                    isSavingChallenge={isSavingChallenge}
                                    handleClearAndDelete={handleClearAndDelete}
                                    loadingCsv={loadingCsv} setLoadingCsv={setLoadingCsv}
                                    progress={progress} setProgress={setProgress}
                                    fileName={fileName} setFileName={setFileName}
                                    setToggleCollapse={setToggleCollapse}
                                    addContextBtn={addContextBtn}
                                    setAddContextBtn={setAddContextBtn}
                                    setAllExpanded={setAllExpanded}
                                    generating={generating}
                                    setGenerating={setGenerating}
                                    selectedGame={selectedGame}
                                    skills={skills}
                                    generateUsingAi={generateUsingAi}
                                    setGenerateUsingAi={setGenerateUsingAi}
                                    prompt={prompt}
                                    setPrompt={setPrompt}
                                    maxQuestions={maxQuestions}
                                    setMaxQuestions={setMaxQuestions}
                                    deleteQuestionList={deleteQuestionList}
                                    setDeleteQuestionList={setDeleteQuestionList}
                                /> : null}
                            {selectedMenuItem.firstColumn === 'Settings' ?
                                <Settings
                                    handleSaveChallenge={handleSaveChallenge}
                                    isSavingChallenge={isSavingChallenge}
                                    handleGoback={handleGoback}
                                    settings={settings}
                                    setSettings={setSettings}
                                    list={list}
                                    selectedGame={selectedGame}
                                    isEditSetting={isEditSetting}
                                    setIsEditSetting={setIsEditSetting}
                                /> : null}
                        </div>
                        <div className={classes.column2}>
                            {selectedMenuItem.secondColumn === 'About' ?
                                <About
                                    selectedGame={selectedGame}
                                    aboutTitle={selectedGame.aboutTitle}
                                    aboutDescription={aboutDescription}
                                    setSelectedMenuItem={setSelectedMenuItem}
                                    menuItemStatus={menuItemStatus}
                                    setMenuItemStatus={setMenuItemStatus}
                                    selectedGBATypeData={selectedGBATypeData}
                                    challengeName={challengeName}
                                    createChallengeGBA={createChallengeGBA}
                                /> : null}

                            {selectedMenuItem.secondColumn === 'ContextList' ?
                                <ContextList
                                    type={selectedGame.type}
                                    selectedGame={selectedGame}
                                    isDisableAll={isDisableAll}
                                    list={list}
                                    setList={setList}
                                    addContextBtn={addContextBtn}
                                    setAddContextBtn={setAddContextBtn}
                                    handleAddContext={handleAddContext}
                                    toggleCollapse={toggleCollapse}
                                    setToggleCollapse={setToggleCollapse}
                                    isDragable={isDragable}
                                    allExpanded={allExpanded}
                                    setAllExpanded={setAllExpanded}
                                    handleExpandCollapseAll={handleExpandCollapseAll}
                                    isContextAdded={isContextAdded}
                                    setIsContextAdded={setIsContextAdded}
                                    skills={skills}
                                    handleDelete={handleSoftDeleteQuestion}
                                    settings={settings}
                                    setSettings={setSettings}
                                    generating={generating}
                                    maxQuestions={maxQuestions}
                                    handleRegenerate={handleRegenerate}
                                />
                                : null}
                        </div>
                        <div className={classes.column3}>
                            <div>
                                <img src={selectedGame.screen} alt='preview' />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    confirmDialogOpen &&
                    <ConfirmDialog
                        open={confirmDialogOpen}
                        setOpen={setConfirmDialogOpen}
                        isDelete={isDelete.current}
                        title={'Confirmation'}
                        description={dialogContent}
                        leftBtnClick={() => handleConfirm(false)}
                        rightBtnClick={() => handleConfirm(true)}
                        rightBtnText={confirmRightBtnText.current}
                        isSaving={confirmLoader}
                    />
                }

                {leastUsedGbaModal &&
                    <Modal
                        open={leastUsedGbaModal}
                        onClose={() => { }}

                        style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            zIndex: '99999',
                        }}
                    >
                        <Box sx={style} className={classes.dialogBoxContainer}>
                            <Typography variant="h6" className={classes.dialogTitle}>
                                These are the least used game designs according to your company’s usage:
                            </Typography>

                            {isUnusedGbasLoading ? <CircularProgress /> : <Grid container spacing={2} className={classes.gridContainer}>

                                {unusedGbaTypes.map((image, index) => (
                                    <Grid item xs={4} key={index}>
                                        <Card className={classes.card} onClick={() => handleUnusedGbaCardClick(image)}>
                                            <CardMedia
                                                component="img"
                                                className={classes.cardMedia}
                                                image={image.bgImgUrl}
                                                alt={`Image ${index + 1}`}
                                            />
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>}

                            <Button className={`${classes.dismissBtn} ${classes.btn}`} onClick={handleDismiss}>DISMISS</Button>

                        </Box>
                    </Modal>

                }



                {
                    generateContentModal &&
                    <GenerateContentModal
                        open={generateContentModal}
                        setOpen={setGenerateContentModal}
                        skills={skills}
                        selectedGame={selectedGame}
                        list={list}
                        setList={setList}
                        topic={topic}
                        setTopic={setTopic}
                        questions={questions}
                        setQuestions={setQuestions}
                    />
                }
            </>
        )
    }


}

export default GameBasedAssessment;
