import React, { useState, useEffect, useContext } from 'react'
import { useRef } from 'react';

//mui
import { makeStyles } from "@mui/styles";
import { ButtonBase } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiCheckbox from "@mui/material/Checkbox";
import Menu from '@mui/material/Menu';

//components
import Label from '../../../custom-styled/label';
import CustomButton from '../../../custom-styled/button';
import CustomSelect from '../../../custom-styled/custom-select';
import Title from '../../../title';

//services
import microskillServices from '../../../../services/microskill';
import documentsServices from '../../../../services/documents';

//global-states
import { GlobalStates } from '../../../../App';

//utils
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';
import useQuery from '../../../../utils/useQuery';

const AzureOpenAi = () => {
    const classes = useStyles();
    let query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates)
    const [ingesting, setIngesting] = useState(false);
    const [localFiles, setLocalFiles] = useState([]);
    const [addMore, setAddMore] = useState(false);
    const [disableAll, setDisableAll] = useState(false);
    const [timer, setTimer] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState([]);
    const [isTabActive, setIsTabActive] = useState(true);
    const countdownInterval = useRef(null);
    const ingestionTimeInSeconds = 600;

    const pdfMimeTypes = ["application/pdf", "application/x-pdf", "application/x-bzpdf", "application/x-gzpdf"];
    const docMimeTypes = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-word.document.macroEnabled.12"];
    const allowedMimetypes = [...pdfMimeTypes, ...docMimeTypes];


    useEffect(() => {
        const storedTimer = localStorage.getItem('countdownTimer');
        const storedTimestamp = localStorage.getItem('countdownTimestamp');
        const length = localStorage.getItem('length');
        
        
        if (storedTimer && storedTimestamp && isTabActive) {
            const remainingTime = ingestionTimeInSeconds - Math.round((Date.now() - parseInt(storedTimestamp, 10)) / 1000);
            if (remainingTime > 0) {
                //set selected files length on refresh
                setTimer(remainingTime);
                startCountdown(remainingTime);
                setDisableAll(true);
                setIngesting(true);
                setLocalFiles(new Array(parseInt(length)));
            } else {
                setDisableAll(false);
                setIngesting(false)
                clearInterval(countdownInterval.current);
                // globalStates.handleToast(true, 'Content ingested successfully!', 'success')
                localStorage.removeItem('countdownTimer');
                localStorage.removeItem('countdownTimestamp');
                localStorage.removeItem('length');
            }
        }

        return () => {
            clearInterval(countdownInterval.current);
        }
    }, [isTabActive]);

    useEffect(() => {
        fetchDocuments(companyId)
    }, [])

    const handleVisibilityChange = () => {
        setIsTabActive(!document.hidden);
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    const fetchDocuments = async (companyId) => {
        const response = await documentsServices.fetchDocuments(companyId);
        // console.log('fetchDocuments response = ', response);
        if (response.status === 200 || response.status === 201) {
            let data = [];
            response.data.data.forEach(async (ele) => {
                if (ele.type === "FOLDER") {
                    const res = await documentsServices.fetchDocuments(companyId, ele.id);
                    // console.log('get folder items res = ', res);
                    if (res.status === 200 || res.status === 201) {
                        let tempSubMenu = [];
                        res.data.data.forEach((item) => {
                            if (item.type !== "FOLDER" && allowedMimetypes.includes(ele.mimeType)) tempSubMenu.push(item);
                        })
                        let temp = { ...ele, subMenu: tempSubMenu }
                        if (tempSubMenu.length) data.push(temp);
                    }
                } else {
                    if (allowedMimetypes.includes(ele.mimeType)) data.push(ele);
                }
            })

            setDocuments(data);
        }
    }

    const startCountdown = (duration) => {
        let countdown = duration;
        countdownInterval.current = setInterval(() => {
            countdown -= 1;
            setTimer(countdown);

            if (countdown <= 0) {
                clearInterval(countdownInterval.current);
                setDisableAll(false);
                setIngesting(false);
                setLocalFiles([]);
                setSelectedDocument([]);
                globalStates.handleToast(true, 'Content ingested successfully!', 'success')
                localStorage.removeItem('countdownTimer');
                localStorage.removeItem('countdownTimestamp');
                localStorage.removeItem('length');
            }
        }, 1000);


    };

    const handleFileUpload = (e) => {
        let files = e.target.files;
        if (addMore) {
            setAddMore(false);
            setLocalFiles((prev) => {
                let prevCopy = { ...prev };
                let len = prev.length;
                for (let i = 0; i < files.length; i++) {
                    prevCopy[len] = files[i];
                    len++
                }
                prevCopy.length = len;
                return prevCopy;
            });
            return
        }
        setLocalFiles(files);
    }

    const handleIngesting = async () => {
        if (addMore) setAddMore(false);
        setIngesting(true);
        setDisableAll(true);
        for (let i = 0; i < selectedDocument.length; i++) {
            let url = `${selectedDocument[i].path}?bypasscor=xx`
            const s3Response = await fetch(url);
            const blob = await s3Response.blob();
            const file = new File([blob], selectedDocument[i].name, { type: selectedDocument[i].mimeType });
            const json = { file: file }
            const payload = await commonUtil.jsonToFormData(json);
            const response = await microskillServices.uploadFileToAzure(payload, companyId);
            if (response.status !== 200) {
                setIngesting(false);
                setDisableAll(false);
                globalStates.handleToast(true, 'Failed to upload file', 'error');
                return
            }
        }


        for (let i = 0; i < localFiles.length; i++) {
            const json = { file: localFiles[i] }
            const payload = await commonUtil.jsonToFormData(json);
            const response = await microskillServices.uploadFileToAzure(payload, companyId);
            console.log('response = ', response);
            if (response.status !== 200) {
                setIngesting(false);
                setDisableAll(false);
                globalStates.handleToast(true, 'Failed to upload file', 'error');
                return
            }
        }

        startCountdown(ingestionTimeInSeconds);
        localStorage.setItem('countdownTimer', ingestionTimeInSeconds);
        localStorage.setItem('countdownTimestamp', Date.now());
        localStorage.setItem('length', getFileCount());
    }

    const handleViewFiles = () => {

    }

    const getSelectedData = () => {
        const data = [];
        selectedDocument.forEach(ele => {
            data.push(ele.id)
        })
        if (Array.isArray(localFiles)) return data;

        for (let i = 0; i < localFiles.length; i++) {
            data.push(i)
        }

        return data
    }

    const getFilesListData = () => {
        const data = []
        selectedDocument.forEach(ele => {
            data.push({
                id: ele.id,
                name: ele?.name
            })
        })
        if (Array.isArray(localFiles)) return data;

        for (const key in localFiles) {
            if (!isNaN(parseInt(key))) {
                data.push({
                    id: parseInt(key),
                    name: localFiles[key]?.name
                })
            }

        }


        return data
    }

    const handleRemoveFiles = (item) => {
        return
        let id = item.id;
        if (id in localFiles) {
            setLocalFiles((prev) => {
                let prevCopy = { ...prev };
                let len = prev.length;
                delete prevCopy[id];
                len--
                prevCopy.length = len;
                console.log('prevCopy = ', prevCopy);
                return prevCopy;
            });

        }
    }

    const getFileCount = () => {
        let count = selectedDocument.length + localFiles.length;
        return count
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className={classes.wrapper}>
            <Label labelText={'Content for AI Generation'} fs='12px' color='#484848' />
            {
                localFiles.length || selectedDocument.length ?
                    <>
                        <div className={classes.detailWrapper}>
                            <span>
                                <i className="fa-regular fa-file"></i>
                                {getFileCount()} {getFileCount() > 1 ? 'files' : 'file'} attached
                            </span>
                            <ButtonBase onClick={handleViewFiles} className={classes.viewFiles} key={getFileCount()}>
                                View files
                                <CustomSelect
                                    hideBaseBtn={true}
                                    defaultText={'View files'}
                                    withSearch={false}
                                    withSelectAllOption={false}
                                    listData={getFilesListData()}
                                    selected={getSelectedData()}
                                    handleSelect={handleRemoveFiles}
                                />
                            </ButtonBase>
                        </div>
                        <div className={classes.btnBox}>

                            {ingesting ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                >
                                    {/* ANALYSING DATA - {timer}s */}
                                    ANALYSING DATA - {formatTime(timer)}
                                </LoadingButton> :
                                <CustomButton
                                    variant='contained'
                                    btnText={'Start training AI'}
                                    bgcolor='#f4511e'
                                    borderColor='#f4511e'
                                    textColor='#fff'
                                    width='200px'
                                    fw={700}
                                    fs={10}
                                    isDisabled={disableAll}
                                    handleClick={handleIngesting}
                                />
                            }
                            {
                                addMore ?
                                    <FileUpload flexDirection='row' handleFileUpload={handleFileUpload} documents={documents} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} /> :
                                    <CustomButton
                                        btnText={'Add more files'}
                                        bgcolor='#fff'
                                        borderColor='#f4511e'
                                        textColor='#f4511e'
                                        width='200px'
                                        fw={700}
                                        fs={10}
                                        handleClick={() => setAddMore(true)}
                                        isDisabled={disableAll}
                                    />
                            }

                        </div>
                    </>
                    : <FileUpload flexDirection='column' handleFileUpload={handleFileUpload} documents={documents} selectedDocument={selectedDocument} setSelectedDocument={setSelectedDocument} />
            }




        </div>
    )
}

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};

const FileUpload = ({ handleFileUpload, flexDirection = 'row', width = '22rem', height = 'auto', documents = [], selectedDocument, setSelectedDocument }) => {
    let defaultMessage = 'No Documents Available'
    const classes = useStyles()
    const acceptedFormats = ['.docx', '.doc', '.pptx', '.ppt', '.pdf'];
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selected, setSelected] = useState([]);
    const [list, setList] = useState([]);
    const [message, setMessage] = useState(defaultMessage);
    const searchInputRef = useRef();

    console.log('list = ', list);
    useEffect(() => {
        if (open) {
            setList(documents);
            setSelected(selectedDocument)
        }
    }, [open])


    const handleBaseBtnClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }

    const handleClose = () => {
        setSelected([]);
        setSelectedFolder(null);
        setOpen(false);
    }

    const handleSearchDocument = (e) => {
        let value = e.target.value;
        if (selectedFolder !== null) setSelectedFolder(null);
        if (value === '') {
            setList(documents);
            setMessage(defaultMessage)
            return
        }
        let result = documents.filter(li => {
            if (li.name.toLowerCase().includes(value.toLowerCase())) return li
            else if (li.name.toLowerCase() === value.toLowerCase()) return li
        });
        setList(result);
        if (!result.length) {
            setMessage('0 results')
        } else {
            setMessage(defaultMessage)
        }
    }

    const handleClick = (item) => {
        console.log('handleClick item = ', item);
        if (item.type === 'FOLDER') {
            setSelectedFolder(item);
            return
        }
        setSelectedFolder(null);
        if (selected.find(ele => ele.id === item.id)) {
            setSelected(selected.filter(ele => ele.id !== item.id))
            return
        }

        setSelected([...selected, item]);

    }

    const isChecked = (item) => {
        if (item.type === 'FOLDER') {
            if (selectedFolder === null) return false;
            return item.id === selectedFolder.id;
        }
        const checked = selected.find(ele => ele.id === item.id)
        return checked?.id === item.id;
    }

    const getIcon = (ele) => {
        if (ele.type === "FOLDER") return (<i className="fa-regular fa-folder"></i>)
        return (<i className="fa-regular fa-file"></i>)
    }

    const handleSubmenuClick = (item) => {
        console.log('handleSubmenuClick item = ', item);
        if (selected.find(ele => ele.id === item.id)) {
            setSelected(selected.filter(ele => ele.id !== item.id))
            return
        }

        setSelected([...selected, item]);
    }

    const handleSave = () => {
        console.log('selected = ', selected);
        setSelectedDocument(selected)
        setOpen(false);
    }

    const handleReset = () => {
        setSelected([]);
        setSelectedDocument([]);
        setSelectedFolder(null);
        setOpen(false);
    }

    return (
        <div
            style={{
                flexDirection,
                width,
                height,
                padding: flexDirection === 'row' ? '0.2rem 0' : '1rem 0',
                gap: flexDirection === 'row' ? '1rem' : '0.6rem',
            }}
            className={`${classes.fileUploadWrapper}`}>
            <div className={classes.row}>
                <label className={classes.uploadIcon}>
                    <i className="fa-solid fa-cloud-arrow-up" style={{ fontSize: flexDirection === 'row' && '1.2rem', marginBottom: flexDirection === 'row' && 0 }}></i>
                    <input style={{ display: 'none' }} type='file' accept={acceptedFormats} onChange={handleFileUpload} multiple />
                </label>
                <h4>Upload reference files here</h4>
            </div>
            {/* <h5>Accepted: pdf, docx</h5> */}
            <h5>or</h5>


            <ButtonBase
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className={`${classes.baseBtn}`}
                onClick={handleBaseBtnClick}

            >
                Select from Library
            </ButtonBase >

            {open && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                className={classes.documentMenu}
                open={open}
                onClose={handleClose}
            >
                <Title titleText={"Select Document"} padding={'0.6rem'} fs={'16px'} />
                <div className={classes.searchInputBox}>
                    <input ref={searchInputRef} placeholder="Search Document" type="text" onChange={handleSearchDocument} />
                    <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <div className={classes.filterWrapper} style={{ width: isEmpty(selectedFolder) ? 'calc(680px / 2)' : '680px' }}>
                    <div className={classes.filterList} style={{ width: isEmpty(selectedFolder) ? 'calc(680px / 2)' : '50%' }}>

                        {
                            list.length ?
                                (<>
                                    {list.map((ele, index) => (
                                        <div key={index} className={classes.listItem}
                                            title={ele.name}
                                            onClick={() => handleClick(ele)}
                                            style={{
                                                // backgroundColor: selectedFolder?.id === ele.id ? '#f9f9f9' : '#fff'
                                                backgroundColor: isChecked(ele) ? '#f9f9f9' : '#fff'
                                            }}>
                                            <Checkbox
                                                id={ele.id}
                                                type="checkbox"
                                                name={ele.name}
                                                isChecked={isChecked(ele)}
                                            />
                                            <span>
                                                {`${commonUtil.truncateString(ele.name, 27)}`}

                                            </span>
                                            <span > {getIcon(ele)}</span>
                                        </div>
                                    ))}
                                </>)
                                : <div style={{
                                    textAlign: 'center',
                                    color: 'rgb(0,0,0,0.7)',
                                    padding: '0.5rem 0'
                                }}>
                                    {message}

                                </div>
                        }
                    </div>
                    {!isEmpty(selectedFolder) ?
                        <div id="sub-menu" className={`${classes.filterList} ${classes.subMenuList}`}>

                            {
                                selectedFolder.subMenu.length ?
                                    (<>
                                        {
                                            // withSelectAll 
                                            false ?
                                                <div className={classes.listItem}
                                                // onClick={handleSelectAll}
                                                >
                                                    <Checkbox
                                                        className={classes.checkBox}
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                    // isChecked={filterList.length && selectedFolder.subMenu.length === selectedFolder.selected.length}
                                                    />
                                                    <span style={{ color: '#f4511e' }}>Select All</span>
                                                </div> : null
                                        }
                                        {selectedFolder.subMenu.map((ele, index) => (
                                            <div key={index} className={classes.listItem}
                                                onClick={() => handleSubmenuClick(ele)}
                                                title={ele.name} style={{
                                                    // backgroundColor: selected.includes(ele.id) ? '#f9f9f9' : '#fff'
                                                }}>
                                                <Checkbox
                                                    id={ele.id}
                                                    type="checkbox"
                                                    name={ele.name}
                                                    // handleClick={handleClick}
                                                    isChecked={isChecked(ele)}
                                                />
                                                <span>{ele.name}</span>
                                                <span>{getIcon(ele)}</span>
                                            </div>
                                        ))}
                                    </>)
                                    : <div style={{
                                        textAlign: 'center',
                                        color: 'rgb(0,0,0,0.7)',
                                        padding: '0.5rem 0'
                                    }}>
                                        No Data Available
                                    </div>
                            }
                        </div> : null}
                </div>

                <div className={classes.filterBtnBox}>
                    <CustomButton
                        variant={'contained'}
                        textColor={'#fff'}
                        borderColor={'#f4511e'}
                        bgcolor={'#f4511e'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Save Selection"}
                        handleClick={handleSave}
                    />
                    <CustomButton
                        variant={'outlined'}
                        textColor={'#f4511e'}
                        borderColor={'#f4511e'}
                        bgcolor={'#fff'}
                        fs={"0.6rem"}
                        fw={700}
                        btnText={"Reset"}
                        handleClick={handleReset}
                    />
                    {/* <h1> {selectedFolder.selected.length} {selectedFolder.name} Selected</h1> */}
                </div>
            </Menu>}

        </div>
    )
}

export default AzureOpenAi




const useStyles = makeStyles(() => ({
    wrapper: {
        // border: '1px solid',
        margin: '1rem 0 0 0'
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    fileUploadWrapper: {

        border: '2px dashed #CCCCCC',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '0.8rem',
        '& h4,h5,p': {
            fontSize: '0.6rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 700,
        },
        '& h5': {
            fontWeight: 500,
        },
    },
    baseBtn: {
        color: '#F4511E',
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '0.6rem',
        fontFamily: "Montserrat",
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    uploadIcon: {
        '& .fa-solid': {
            fontSize: '1.5rem',
            color: '#F4511E',
            cursor: 'pointer',
            marginBottom: '0.3rem',
            transition: '0.4s',
            '&:hover': {
                scale: 1.2
            }
        },

    },
    btnBox: {
        display: 'flex',
        marginTop: '1.5rem',
        gap: '3rem',
        '& > button': {
            borderRadius: '0.1rem'
        },
        '& > div': {
            marginTop: 0
        },
        '& .MuiLoadingButton-root': {
            width: 200,
            fontSize: 10,
            fontWeight: 700,
            fontFamily: 'Montserrat',
            backgroundColor: '#ccc',
            textTransform: 'unset'
        }

    },
    detailWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        marginTop: '1rem',
        '& > span': {
            fontSize: '0.8rem',
            fontWeight: 700,
            fontFamily: 'Montserrat',
            '& > i': {
                marginRight: '0.5rem'
            }
        },
        '& > button': {
            color: '#f4511e',
            fontSize: '0.8rem',
            fontWeight: 700,
            fontFamily: 'Montserrat',
            textDecoration: 'underline',
            '& > span': {
                width: '18px !important',
                height: '18px !important',
                marginRight: '0.8rem',
                color: '#f4511e'
            }
        }
    },
    viewFiles: {
        position: 'relative',
        '& > button': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '155px',

        },
    },

    //menu-styles
    documentMenu: {
        zIndex: 99999,
        '& > .MuiPaper-root': {
            // top: "17% !important",
            // left: "60% !important",
            // width: "500px",
            // height: "46px",

            '& > .MuiList-root': {
                // display: "flex",
                // padding: "6px",
                // height: "46px"
            }
        }
    },
    searchInputBox: {
        backgroundColor: "#fff",
        border: '1px solid rgb(112,112,112,0.4)',
        padding: '0rem 1rem',
        height: '2rem',
        width: '318px',
        display: 'flex',
        alignItems: 'center',
        margin: '0.5rem 0.3rem',
        borderRadius: '3px',
        "& input": {
            width: "95%",
            backgroundColor: "#fff",
            border: "none",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "600",
            color: "#888888",
            "&:focus": {
                outline: "none"
            }
        },
        "& i": {
            color: "#888888"
        }
    },
    filterWrapper: {
        display: 'flex',
        height: '300px',
    },

    filterList: {
        height: '100%',
        // width: '30%',
        'overflow-y': 'auto',
        "&::-webkit-scrollbar": {
            width: "7px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f9f9f9",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: '#f4511e'

        }
    },
    subMenuList: {
        backgroundColor: '#f9f9f9',
        width: '50%',
        '& > div': {
            "&:hover": {
                cursor: 'pointer',
                // backgroundColor: '#FFFFFF',
            }
        }
    },
    listItem: {
        position: 'relative',
        paddingLeft: "0.5rem",
        height: '2.6rem',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: '0.5rem',
        "& span": {
            // paddingLeft: "0.3rem",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
            color: "#484848",
        },
        '& > span:first-child': {
            padding: '0.3rem'
        },
        '& > span:nth-child(2)': {
            width: 'fit-content',
            maxWidth: 'fit-content',
            height: '1rem',
            maxHeight: '1rem',
            overflow: 'hidden'
        },
        '& > span:nth-child(3)': {
            position: 'absolute',
            right: '0.8rem',
            '& > i': {
                fontSize: '1rem'
            }
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9 !important'
        }
    },
    selectAllLi: {
        padding: "0 0.5rem",
        height: '2.3rem',
        "& span": {
            paddingLeft: "0.3rem",
            color: "#F4511E",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f9f9f9'
        }
    },
    filterBtnBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: '1rem',
        position: 'relative',
        '& > .MuiButtonBase-root': {
            width: '150px',
            height: '38px',
            minWidth: '100px',
            padding: 0,
            fontWeight: 800,
            letterSpacing: 1
        },
        '& > .MuiButton-contained': {
            backgroundColor: '#f4511e',
            color: '#fff',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#f4511e',
                color: '#fff',
                borderColor: '#f4511e'
            }
        },
        '& > .MuiButton-outlined': {
            backgroundColor: '#fff',
            color: '#f4511e',
            borderColor: '#f4511e',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

            }
        },
        '& > h1': {
            position: "absolute",
            right: "3%",
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 600,
        }
    }
}))

