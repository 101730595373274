import React, { useState } from 'react'

//  mui
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiCheckbox from "@mui/material/Checkbox";
import { Add } from '@mui/icons-material';

//components
import SearchBox from '../../../search-box';
import CustomSelect from '../../../custom-styled/custom-select';
import ControlledSwitches from '../../../custom-styled/switch-button';

//styles
import useStyles from '../../index.styles';
import commonUtil from '../../../../utils/commonUtil';
import Label from '../../../custom-styled/label';

import { CONTENT_TYPES } from '../../index';

const Step1 = ({
    userType,
    setLaunchType,
    contentType,
    setContentType,
    setStep2,
    setStep3,
    companies,
    companiesObject,
    selectedCompany,
    setSelectedCompany,
    _selectCompany,
    groups,
    groupsObject,
    selectedGroups,
    setSelectedGroups,
    _selectGroup,
    microskills,
    microskillsObject,
    selectedMicroskills,
    setSelectedMicroskills,
    _selectMicroskills,
    _selectAllMicroskills,
    forms,
    formsObject,
    selectedForms,
    setSelectedForms,
    _selectForm,
    documents,
    documentsObject,
    selectedDocuments,
    setSelectedDocuments,
    _selectDocuments,
    _selectAllDocuments,
    unlockChallenges = false,
    setUnlockChallenges,
    selectedNav,
    LAUNCHPG_NAV_TYPES,
    clearGroups,
    clearSelectedMs
}) => {
    const CONTENT_LIST = Object.keys(CONTENT_TYPES).map(item => ({ id: item, name: commonUtil.capitalize(item.toLowerCase()) }));
    const classes = useStyles();
    let roles = localStorage.getItem('roles') || [];
    const chooseContentType = (item) => {
        // console.log('chooseContentType item = ', item);
        if (contentType.includes(item.id)) {
            return
        }
        // setContentType([...contentType, item.id]); //for selecting multiple content
        setContentType([item.id]);
        setStep2(false);
        setStep3(false);
        setLaunchType([]);
        setSelectedMicroskills([]);
        setSelectedForms([]);
        setSelectedGroups([]);
        setSelectedDocuments([]);
    }

   
    return (
        <div>
            <h2 style={{ fontSize: '1.2rem', fontFamily: "Montserrat", color: "#707070", padding: '1rem 0rem' }}>Step 1: Content</h2>
            {userType === "superAdmin" &&
                <div className={classes.row}>
                    <CustomSelect
                        defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Company', selected: selectedCompany, dataObject: companiesObject })}
                        autoClose={true}
                        withSearch={true}
                        withSelectAllOption={false}
                        listData={companies}
                        selected={selectedCompany}
                        handleSelect={_selectCompany}
                    />
                </div>}
            <div className={`${classes.row} ${classes.customRow}`}
                style={{ marginBottom: contentType.includes(CONTENT_TYPES.MICROSKILLS) && '1.5rem' }}//unlock-challenges
            >
                {
                    contentType.includes(CONTENT_TYPES.MICROSKILLS) || contentType.includes(CONTENT_TYPES.DOCUMENTS) ?
                        <CustomSelect
                            defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Groups', selected: selectedGroups, dataObject: groupsObject })}
                            autoClose={false}
                            // autoClose={true}
                            withSearch={true}
                            withSelectAllOption={false}
                            listData={groups}
                            selected={selectedGroups}
                            handleSelect={_selectGroup}
                            withClear={true}
                            // setSelected={setSelectedGroups}
                            handleClear={clearGroups}
                        /> : null
                }

                {
                    contentType.includes(CONTENT_TYPES.MICROSKILLS) && (
                        <>
                            <CustomSelect
                                defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Microskill', selected: selectedMicroskills, dataObject: microskillsObject })}
                                autoClose={false}
                                withSearch={true}
                                withSelectAllOption={true}
                                listData={microskills}
                                selected={selectedMicroskills}
                                handleSelect={_selectMicroskills}
                                customSelectAll={true}
                                _selectAll={_selectAllMicroskills}
                                withClear={true}
                                setSelected={setSelectedMicroskills}
                                handleClear={clearSelectedMs}
                            />
                        </>
                    )
                }

                {
                    contentType.includes(CONTENT_TYPES.FORMS) && (
                        <CustomSelect
                            defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Form', selected: selectedForms, dataObject: formsObject })}
                            autoClose={true}
                            withSearch={true}
                            withSelectAllOption={false}
                            listData={forms}
                            selected={selectedForms}
                            handleSelect={_selectForm}
                            withClear={true}
                            setSelected={setSelectedForms}
                        />
                    )
                }

                {
                    contentType.includes(CONTENT_TYPES.DOCUMENTS) && (
                        <>
                            <CustomSelect
                                defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Document', selected: selectedDocuments, dataObject: documentsObject })}
                                autoClose={false}
                                withSearch={true}
                                withSelectAllOption={true}
                                listData={documents}
                                name={"documents"}
                                selected={selectedDocuments}
                                handleSelect={_selectDocuments}
                                customSelectAll={true}
                                _selectAll={_selectAllDocuments}
                                withClear={true}
                                setSelected={setSelectedDocuments}
                            />
                        </>
                    )
                }

            </div>
            {
            // roles.includes("UNLOCK_CHALLENGES") && 
            contentType.includes(CONTENT_TYPES.MICROSKILLS) && selectedNav !== LAUNCHPG_NAV_TYPES[1] &&
                <>
                    <Checkbox
                        id='unlock-challenges'
                        type='checkbox'
                        name='unlock-challenges'
                        handleClick={() => setUnlockChallenges(!unlockChallenges)}
                        isChecked={unlockChallenges}
                    />
                    <Label labelText={'Unlock Challenges'} htmlFor='unlock-challenges' fs='0.8rem' color='rgb(0, 0, 0, 0.9)' fw={700} />
                </>
            }
            {
                selectedNav !== LAUNCHPG_NAV_TYPES[1] && (
                    <div className={classes.addContentBtn}>
                        <Add /> Add Content
                        <CustomSelect
                            hideBaseBtn={true}
                            defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Add Content', selected: [], })}
                            autoClose={true}
                            withSearch={false}
                            withSelectAllOption={false}
                            listData={CONTENT_LIST}
                            selected={contentType}
                            handleSelect={chooseContentType}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default Step1;

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};